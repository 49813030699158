import { styled } from "@mui/material/styles";
import { OutlinedInput, Select, InputBase, TextField } from "@mui/material";

export const StyledTextBox = styled(OutlinedInput)(({ theme }) => ({
  boxShadow: ["none"],
  // border: "1px solid #C8D2D8",
  borderRadius: "8px",
  height: "3em",
}));

export const StyledSelectTextBox = styled(Select)(({ theme }) => ({
  boxShadow: ["none"],
  // border: "1px solid #C8D2D8",
  borderRadius: "8px",
  height: "3em",
}));

export const SingleTextBox = styled(OutlinedInput)(({ theme }) => ({
  boxShadow: ["none"],
  border: "1px solid #C8D2D8",
  borderRadius: "6px",
  height: "2.125em",
}));

export const FilterTextBox = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    height: "2em",
  },
  boxShadow: ["none"],
  border: "1px solid #718394",
  borderRadius: "4px",
  width: "12.0625em",
}));

export const AnimatedSearchBar = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: "8px",
    position: "relative",
    fontSize: 16,
    width: "10px",
    padding: "10px 12px",
    color: "transparent",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
      color: "black",
      outline: "none",
      width: "18.75em",
      transition: "width 200ms ease-in",
      border: "1px solid #ced4da",
    },
    "&:focus::placeholder": {
      color: "#ABABAB",
    },
  },
}));

export const WideTextBox = styled(OutlinedInput)(({ theme }) => ({
  boxShadow: ["none"],
  border: "1px solid #C8D2D8",
  borderRadius: "4px",
  height: "2em",
}));

// The textbox used in help and support section

export const StyledTextArea = styled(TextField)(({ theme }) => ({
  boxShadow: ["none"],
  border: "1px solid #CED0D2",
  borderRadius: "8px",
}));
