// import handler from "./handler";
import { BASE_URL } from "../utils/constants";

export const postOrganizationsData = async(value) =>{
    let accestoken = localStorage.getItem('accessToken')
	try {
        let response = await fetch(`${BASE_URL}/core/organizations`, {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${accestoken}`,
            'Content-Type': 'application/json',
          },
        body: JSON.stringify(value)
		})
		return response
    } catch (e) {
        throw e;
    }
}

export const updateOrganizationsData = async(value) =>{
    let accestoken = localStorage.getItem('accessToken')
	try {
        let response = await fetch(`${BASE_URL}/core/organizations/update`, {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${accestoken}`,
            'Content-Type': 'application/json',
          },
        body: JSON.stringify(value)
		})
		return response
    } catch (e) {
        throw e;
    }
}