import handler from "./handler";
import { BASE_URL } from "../utils/constants";

export const getDashboard = async () => {
  let accestoken = localStorage.getItem("accessToken");
  let organizationId = localStorage.getItem("organizationId");
  try {
    let response = await fetch(
      `${BASE_URL}/dashboard/dashboards/organizations/${organizationId}`,
      {
        method: "GET",
        headers: {
            Authorization: `Bearer ${accestoken}`,
        },
      }
    );
    let result = handler(response);

    return result;
  } catch (e) {
    throw e;
  }
};


export const getDashboardWidgets = async (value) => {
  let accestoken = localStorage.getItem("accessToken");
  let dashboardId = value
  try {
    let response = await fetch(
      `${BASE_URL}/dashboard/widgets/dashboards/${dashboardId}`,
      {
        method: "GET",
        headers: {
            Authorization: `Bearer ${accestoken}`,
        },
      }
    );
    let result = handler(response);

    return result;
  } catch (e) {
    throw e;
  }
};

export const postDashboard = async (value) => {
  // console.log('value : ' + JSON.stringify(value));
  let accestoken = localStorage.getItem("accessToken");
  try {
    let response = await fetch(`${BASE_URL}/dashboard/dashboards`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accestoken}`,
      },
      body: JSON.stringify(value),
    });
    console.log(response)
    return response
  } catch (e) {
    throw e;
  }
};


export const DeleteDashboard = async (dashboardId) => {
  let accestoken = localStorage.getItem("accessToken");
  try {
    let response = await fetch(
      `${BASE_URL}/dashboard/dashboards/delete/${dashboardId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accestoken}`,
        },
      }
    );
    let result = handler(response);

    return result;
  } catch (e) {
    throw e;
  }
};


export const updateDashboard = async (value) => {
  let accestoken = localStorage.getItem("accessToken");
  try {
    let response = await fetch(`${BASE_URL}/dashboard/dashboards/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accestoken}`,
      },
      body: JSON.stringify(value),
    });
    
    return response;
  } catch (e) {
    throw e;
  }
};
