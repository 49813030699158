import handler from "./handler";
import { BASE_URL } from "../utils/constants";

export const postUserRegister = async (value) => {
  // console.log('value : ' + JSON.stringify(value));
  try {
    let response = await fetch(`${BASE_URL}/core/auth/signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(value),
    });
    console.log(response);
    return response;
  } catch (e) {
    throw e;
  }
};

export const requestAccessToken = async (value) => {
  try {
    let response = await fetch(`${BASE_URL}/core/auth/openid-connect/token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(value)
    });
    let result = handler(response);

    if (response.ok) {
      await result.then((data) => {
        // console.log(data.access_token);
        localStorage.setItem("accessToken", data.access_token);
      });

      localStorage.setItem("email", value.email);

      const accessToken = localStorage.getItem("accessToken");
      const email = localStorage.getItem("email");

      if (accessToken !== null || email !== null) {
        return response;
      }
    } else {
      return response;
    }

    // console.log('accessToken: ' + accessToken + ' ' + 'email: ' + email)

    // console.log('true')
    //     const data = await response.json();
    // return {response, data};
  } catch (e) {
    throw e;
  }
};

export const getUserDetails = async () => {
  let accestoken = localStorage.getItem("accessToken");

  try {
    let response = await fetch(`${BASE_URL}/core/users/token`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accestoken}`,
      },
    });
    let result = handler(response);
    return result;
  } catch (e) {
    throw e;
  }
};

export const updateUserData = async (value) => {
  let accestoken = localStorage.getItem("accessToken");
  try {
    let response = await fetch(`${BASE_URL}/core/users/update`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accestoken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(value),
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export const passwordResetLink = async (value) => {
  // console.log('value : ' + JSON.stringify(value));
  try {
    let response = await fetch(`${BASE_URL}/core/auth/reset-password-link`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(value),
    });
    return response;
  } catch (e) {
    throw e;
  }
};


export const passwordReset = async (value) => {
  // console.log('value : ' + JSON.stringify(value));
  let accestoken = localStorage.getItem("accessToken");
  try {
    let response = await fetch(`${BASE_URL}/core/users/reset-password`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accestoken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(value),
    });
    return response;
  } catch (e) {
    throw e;
  }
};
