import * as React from "react";
import {
  Box,
  CssBaseline,
  IconButton,
  Toolbar,
  Typography,
  Stack,
  ListItem,
  // InputAdornment,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  Avatar
} from "@mui/material";

import {
  // NotificationsNoneOutlined,
  // AccountCircleRounded,
  // SearchOutlined,
  MenuOutlined,
} from "@mui/icons-material";
import { useState, useEffect } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
// import { AnimatedSearchBar } from "../components/TextBox";
import MainLogo from "../assets/fulllogo.svg";
import { Theme } from "../components/Theme";
import { getUserDetails } from "../services/users.services";
import { EditDrawer, AppBar, DrawerHeader } from "../components/Drawer";

import {
  LogoutOutlined,
  HomeOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";

import SubNavigation from "./SubNavigation";
import "../assets/style.css";

const drawerWidth = 240;

function DashboardNavigation(props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [userDetails, setUserDetails] = useState(null)


  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      navigate("/signin");
    }
    (async () => {
      try {
        await getUserDetails().then((data) => {
          setUserDetails(data)

          if (data === undefined || data === null) {
            localStorage.clear();
            navigate("/signin");
          }
          localStorage.setItem("organizationId", data.organization.encryptedId);
        });
      } catch (e) {
        throw e;
      }
    })();
        // eslint-disable-next-line
  }, []);

  if(!userDetails) return null

  const handleDrawerOpen = () => {
    open ? setOpen(false) : setOpen(true);
  };

  const handleLogOut = () => {
    localStorage.clear();
    navigate("/signin");
  };

  const drawer = (
    <div>
      <DrawerHeader sx={{ justifyContent: "flex-start", px: open ? 3 : 2 }}>
      {open ? (
          <NavLink
          to="/unicorn-hub"

        >
          <img src={MainLogo} alt="Logo" />
          </NavLink>
        ) : (
          <NavLink
          to="/unicorn-hub"

        >
          <img
            src="https://unicorn-dev-assests.s3.amazonaws.com/unicornMiniIcon.svg"
            alt="unicorn"
          />
        </NavLink>
          
        )}
      </DrawerHeader>
      <ListItem>
        <IconButton
          sx={{
            minWidth: 0,
            mr: open ? 2 : "auto",
            justifyContent: "center",
            px: 0.5,
          }}
          onClick={handleDrawerOpen}
        >
          <MenuOutlined style={{ color: "neutral.Black_Lit", fontSize: 24 }} />
        </IconButton>
      </ListItem>
      {/* <Toolbar /> */}

      <Stack justifyContent="space-between">
        <Stack>
          <NavLink
            to="/unicorn-hub"
            className={({ isActive }) =>
              isActive ? "activeText" : "inActiveText"
            }
          >
            <ListItem>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  justifyContent: "center",
                }}
              >
                <HomeOutlined
                  style={{ color: "neutral.Black_Lit", fontSize: 24 }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2" sx={{ opacity: open ? 1 : 0 }}>
                    Home
                  </Typography>
                }
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItem>
          </NavLink>

          {/* <ListItem>
            <ListItemButton
              sx={{
                justifyContent: open ? "initial" : "center",
                px: 0.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  justifyContent: "center",
                }}
              >
                <PlusCircleOutlined
                  style={{ color: "neutral.Black_Lit", fontSize: 24 }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2" sx={{ opacity: open ? 1 : 0 }}>
                    New Dashboard
                  </Typography>
                }
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem> */}

          {/* <ListItem>
            <ListItemButton
              sx={{
                justifyContent: open ? "initial" : "center",
                px: 0.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  justifyContent: "center",
                }}
              >
                <FolderOpenOutlined
                  style={{ color: "neutral.Black_Lit", fontSize: 24 }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2" sx={{ opacity: open ? 1 : 0 }}>
                    Collections
                  </Typography>
                }
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem> */}

          {/* <ListItem>
            <ListItemButton
              sx={{
                justifyContent: open ? "initial" : "center",
                px: 0.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  justifyContent: "center",
                }}
              >
                <DatabaseOutlined
                  style={{ color: "neutral.Black_Lit", fontSize: 24 }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2" sx={{ opacity: open ? 1 : 0 }}>
                    Data Sets
                  </Typography>
                }
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem> */}

          {/* <ListItem>
            <ListItemButton
              sx={{
                justifyContent: open ? "initial" : "center",
                px: 0.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  justifyContent: "center",
                }}
              >
                <AppstoreAddOutlined
                  style={{ color: "neutral.Black_Lit", fontSize: 24 }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2" sx={{ opacity: open ? 1 : 0 }}>
                    Applications
                  </Typography>
                }
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem> */}

          <NavLink
            to="/help-and-support"
            className={({ isActive }) =>
              isActive ? "activeText" : "inActiveText"
            }
          >
            <ListItem>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  justifyContent: "center",
                }}
              >
                <QuestionCircleOutlined
                  style={{ color: "neutral.Black_Lit", fontSize: 24 }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2" sx={{ opacity: open ? 1 : 0 }}>
                    Help and Support
                  </Typography>
                }
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItem>
          </NavLink>
          <NavLink
            to="/settings"
            className={({ isActive }) =>
              isActive ? "activeText" : "inActiveText"
            }
          >
            <ListItem>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  justifyContent: "center",
                }}
              >
                <SettingOutlined
                  style={{ color: "neutral.Black_Lit", fontSize: 24 }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2" sx={{ opacity: open ? 1 : 0 }}>
                    Settings
                  </Typography>
                }
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItem>
          </NavLink>
          <ListItem
            className="inActiveTextLogOut"
            onClick={(e) => handleLogOut(e)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 2 : "auto",
                justifyContent: "center",
              }}
            >
              <LogoutOutlined
                style={{ color: "neutral.Black_Lit", fontSize: 24 }}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body2" sx={{ opacity: open ? 1 : 0 }}>
                  Logout
                </Typography>
              }
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItem>
        </Stack>

        {/* <Stack alignItems="flex-end">
          <ListItem>
            <ListItemButton
              sx={{
                justifyContent: open ? "initial" : "center",
                px: 0.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  justifyContent: "center",
                }}
              >
                <img
                  src="https://unicorn-dev-assests.s3.amazonaws.com/logounicorn.svg"
                  alt="unicorn"
                  style={{ fontSize: 24 }}
                />
              </ListItemIcon>
              <NavLink to="/unicorn-hub">
              <ListItemText
                primary={
                  <Typography variant="body2" sx={{ opacity: open ? 1 : 0 }}>
                    Unicorn Hub
                  </Typography>
                }
                sx={{ opacity: open ? 1 : 0 }}
              />
              </NavLink>
              
            </ListItemButton>
          </ListItem>
        </Stack> */}
      </Stack>
    </div>
  );

  return (
    <ThemeProvider theme={Theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <EditDrawer variant="permanent" open={open}>
          {drawer}
        </EditDrawer>
        <AppBar
          justifyContent="space-between"
          position="fixed"
          open={open}
          sx={{ boxShadow: ["none"], zIndex: 1 }}
        >
          <Toolbar>
            <Stack
              width="100%"
              justifyContent="space-between"
              direction="row"
              alignItems="center"
            >
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                Business Analytics
              </Typography>

              {/* right end navigation elements search bar, notification, AccountCircle*/}
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="flex-end"
              >
                {/* <AnimatedSearchBar
                  placeholder="Search"
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchOutlined
                        sx={{
                          position: "absolute",

                          right: 2,

                          color: "#FFFFFF",
                        }}
                        edge="end"
                      ></SearchOutlined>
                    </InputAdornment>
                  }
                />
                <NotificationsNoneOutlined />
                <AccountCircleRounded /> */}
                <NavLink to="/settings" sx={{textDecoration: 'none'}}>
                  <Avatar alt={userDetails.user.firstName} src={userDetails.user.imageUrlPath} />
                </NavLink>
              </Stack>
            </Stack>
          </Toolbar>

          <SubNavigation />
        </AppBar>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <br></br>
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default DashboardNavigation;
