import * as React from "react";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import {
  Stack,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";

import { Theme } from "../components/Theme";
import { ThemeProvider } from "@mui/material";

import {
  postWidgets,
  postWidgetSize,
  postWidgetPosition,
} from "../services/widgets.services";
import { getUserDetails } from "../services/users.services";
import { getPlatformsStores } from "../services/platforms.services";
import { getCharts } from "../services/charts.services";
import { useLocation } from "react-router-dom";

export default function ChartTypeDrawer(props) {
  const location = useLocation();
  const { dashboardId, collectionId } = location.state;
  const [charts, setCharts] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [platformStores, setPlatformsStores] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(false);
      try {
        await getUserDetails().then((data) => {
          setIsLoading(true);
          setUserDetails(data);
        });

        await getCharts().then((data) => {
          setIsLoading(true);
          setCharts(data);
        });

        await getPlatformsStores().then((data) => {
          setIsLoading(true);
          setPlatformsStores(data.map((row) => row.encryptedId));
        });
      } catch (e) {
        throw e;
      }
    })();
  }, []);

  if (!userDetails || !charts || !platformStores) return null;



  // console.log(collectionId)
  const handlePostWidgets = (e, chartTypeId) => {
    e.preventDefault();

    const value = {
      chartTypeId: chartTypeId,
      collectionId: collectionId,
      createdType: "CUSTOMIZE",
      dashboardId: dashboardId,
      description: "no description",
      name: "Chart",
      organizationId: localStorage.getItem("organizationId"),
      platformDataType: "SALES",
      platformStoreIds: [platformStores[0]],
      widgetColor: '#058DC7'
    };
    setIsLoading(false);
    postWidgets(value).then((data) => {
      
      if (data.response.ok) {
        const widgetSizeValue = {
          height: 300,
          widgetId: data.data.id,
          width: 300,
        };

        const widgetSizePosition = {
          xaxis: 40,
          widgetId: data.data.id,
          yaxis: 10,
        };
        
        setIsLoading(true);
        postWidgetSize(widgetSizeValue);
        postWidgetPosition(widgetSizePosition);
        // const newValue = { ...value, ...{ id: data.data.id } };
        // props.callBack(newValue);
        localStorage.setItem("createNewStatus", true);
        //window.location.reload(false);
      }
    });
  };


  return (
    <div>
      <ThemeProvider theme={Theme}>
        <Box sx={{ width: 240 }}>
        

          <Typography
            variant="body2"
            sx={{ marginLeft: "5%", marginTop: "8%", marginBottom: "6%" }}
          >
            Charts
          </Typography>
          <Grid container >
            {!isLoading ? (
              <CircularProgress sx={{marginLeft: '30%'}}/>
            ) : (
              charts.map((row) => (
                <Grid xs={3} marginBottom="5%">
                  <Stack
                    alignItems="center"
                    spacing={1}
                    sx={{ cursor: "pointer" }}
                    onClick={(e) => handlePostWidgets(e, row.id)}
                  >
                    <img src={row.imageURLPath} alt={row.name} />
                    <Typography variant="body4" sx={{textTransform: 'capitalize'}}>{row.name}</Typography>
                  </Stack>
                </Grid>
              ))
            )}
          </Grid>
        </Box>
      </ThemeProvider>
    </div>
  );
}
