// Import Highcharts
import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Rnd } from "react-rnd";
import {
  Stack,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
// import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import OpenInFullOutlinedIcon from "@mui/icons-material/OpenInFullOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Theme } from "../Theme";
import { ButtonPrimaryNormalDelete, ButtonTertiaryNormal } from "../Buttons";
import moment from "moment";
import {
  DeleteWidget,
  getWidgetSize,
  updateWidgetSize,
  getWidgetPosition,
  updateWidgetPosition,
} from "../../services/widgets.services";
import { gql, useQuery } from "@apollo/client";
import "../../assets/style.css";
import { getChartsById } from "../../services/charts.services";
import { groupByDate } from "../../utils/utils";
import CloseIcon from "@mui/icons-material/Close";

export default function EditCharts(props) {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const dashboardData = props.dashboardData;

  const [widgetSize, setWidgetSize] = useState(null);
  const [widgetPosition, setWidgetPosition] = useState(null);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  // const [purchaseDate, setPurchaseDate] = useState({});
  // const [totalSales, setTotalSales] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [chartType, setChartType] = useState(null);
  const [chartDate, setChartData] = useState(null);

  // const platformStoreIds =
  //   Object.keys(props.dashboardData).length === 0
  //     ? null
  //     : props.dashboardData.platformStoreIds.map((row) => row);

  // let platformStoreIdsToString =
  //   Object.keys(props.dashboardData).length === 0
  //     ? null
  //     : platformStoreIds.toString();


  const QUERY = gql`
  {
    filterPlatformDataByWidgetId(
      widgetId: "${dashboardData.id}"
    ) {
      sales {
        purchaseDate
        totalSalesAmount
      }
    }
  }
`;

  // console.log(dashboardData);
  const { data, refetch } = useQuery(QUERY);

// console.log(data)

  useEffect(() => {
    (async () => {
      setIsLoading(false);
      try {
        refetch()
        await getWidgetSize(dashboardData.id).then((data) => {
          setWidgetSize(data);
          setWidth(data.width);
          setHeight(data.height);
          setIsLoading(true);
        });

        await getWidgetPosition(dashboardData.id).then((data) => {
          setWidgetPosition(data);
          setPositionX(data.xaxis);
          setPositionY(data.yaxis);
          setIsLoading(true);
        });

        await getChartsById(dashboardData.chartTypeId).then((data) => {
          setChartType(data);
          setIsLoading(true);
        });

        setChartData(Object.keys(data.filterPlatformDataByWidgetId.sales).length <= 0 ? [] : groupByDate(data.filterPlatformDataByWidgetId.sales));
        //   setPurchaseDate(
        //     data?.findByPlatformStoreId.sales.map((d) => d.purchaseDate)
        //   );
        // setTotalSales(
        //     data?.findByPlatformStoreId.sales.map((d) => d.totalSalesAmount)
        //   );
      } catch (e) {
        throw e;
      }
    })();
  }, [data, dashboardData, refetch]);

  // console.log(dashboardData)
  useEffect(() => {
    (async () => {
      const value = {
        height: parseInt(height),
        id: widgetSize.id,
        widgetId: dashboardData.id,
        width: parseInt(width),
      };
      // console.log("value2: " + value.width + " widgetId: " + value.widgetId);

      // localStorage.setItem(
      //   'size_'+dashboardData.id,
      //   JSON.stringify(value)
      // );

      updateWidgetSize(value);
    })();
  }, [height, width, dashboardData, widgetSize]);

  useEffect(() => {
    (async () => {
      const value = {
        xaxis: parseInt(positionX),
        id: widgetPosition.id,
        widgetId: dashboardData.id,
        yaxis: parseInt(positionY),
      };
      // console.log("value2: " + value.width + " widgetId: " + value.widgetId);
      // localStorage.setItem(
      //   'position_'+dashboardData.id,
      //   JSON.stringify(value)
      // );

      updateWidgetPosition(value);
    })();
  }, [positionX, positionY, dashboardData, widgetPosition]);

  if (!chartType || !widgetSize || !widgetPosition || !chartDate) return null;

  const handleDeleteWidget = (e) => {
    e.preventDefault();

    const value = dashboardData.id;

    DeleteWidget(value).then((data) => {
      if (data.ok) {
        handleClose();
        // window.location.reload(false);
        localStorage.setItem("deleteStatus", true);
      }
    });
  };
  // console.log(data)
  // console.log(chartDate)
  // console.log(totalSales.findByPlatformStoreId.sales.map((row) =>  [{name: row.purchaseDate, y: row.totalSalesAmount}]))

  const chartOptions = {
    title: {
      text: undefined,
    },
    chart: {
      type: chartType.name,
      width: parseInt(width),
      height: parseInt(height) - 55,
      
    },
    colors: [dashboardData.widgetColor],
    xAxis: {
      title: {
        text: "Sales Date",
      },
      categories:
        chartDate !== undefined
          ? chartDate
              .sort(
                (a, b) =>
                  parseFloat(moment(a.purchaseDate).format("MMDDY")) -
                  parseFloat(moment(b.purchaseDate).format("MMDDY"))
              )
              .map((data) => {
                return moment(data.purchaseDate).format("MMM, y");
              })
          : [],
      crosshair: true,
    },
    yAxis: {
      title: {
        text: "Sales (USD)",
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        animation: false,
        name: "Sales",
        data:
          chartDate !== undefined
            ? chartDate
                .sort(
                  (a, b) =>
                    parseFloat(moment(a.purchaseDate).format("MMDDY")) -
                    parseFloat(moment(b.purchaseDate).format("MMDDY"))
                )
                .map((data) => {
                  return data.totalSalesAmountTotal;
                })
            : [],
      },
    ],
    credits: {
      enabled: false,
    }, // The water mark removal place
  };

  const chartOptionsModal = {
    title: {
      text: undefined,
    },
    chart: {
      type: chartType.name,
    },
    xAxis: {
      title: {
        text: "Sales Date",
      },
      categories:
        chartDate !== undefined
          ? chartDate
              .sort(
                (a, b) =>
                  parseFloat(moment(a.purchaseDate).format("MMDDY")) -
                  parseFloat(moment(b.purchaseDate).format("MMDDY"))
              )
              .map((data) => {
                return moment(data.purchaseDate).format("MMM");
              })
          : [],
      crosshair: true,
    },
    colors: [dashboardData.widgetColor],
    yAxis: {
      title: {
        text: "Sales (USD)",
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Sales",
        data:
          chartDate !== undefined
            ? chartDate
                .sort(
                  (a, b) =>
                    parseFloat(moment(a.purchaseDate).format("MMDDY")) -
                    parseFloat(moment(b.purchaseDate).format("MMDDY"))
                )
                .map((data) => {
                  return data.totalSalesAmountTotal;
                })
            : [],
      },
    ],
    credits: {
      enabled: false,
    }, // The water mark removal place
  };

  return (
    <>
      {!isLoading ? (
        <img
          src="https://unicorn-dev-assests.s3.amazonaws.com/unicornMiniIcon.svg"
          alt="unicorn"
          className="rotate"
        />
      ) : (
        <Rnd
          style={{
            backgroundColor: "rgba(200, 210, 216, 0.4)",
            boxShadow: "0px 4px 18px rgba(13, 51, 115, 0.16)",
            borderRadius: "8px",
            border:
              props.checkActive === dashboardData.id ? "1px solid #2294DD" : "",
            padding: 2,
          }}
          // resizeGrid={[10, 10]}
          // dragGrid={[10, 10]}
          size={{ width: width, height: height }}
          position={{ x: positionX, y: positionY }}
          onDragStop={(e, d) => {
            if (positionX < 0) {
              setPositionX(0);
            } else {
              setPositionX(d.x);
            }

            if (positionY < 0) {
              setPositionY(0);
            } else {
              setPositionY(d.y);
            }
          }}
          onResizeStop={(e, direction, ref, delta) => {
            setWidth(ref.style.width);
            setHeight(ref.style.height);
          }}
          minWidth={300}
          minHeight={300}
          // bounds="parent"
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ padding: "10px 0px 0px 15px", backgroundColor: "#fff" }}
            alignItems="center"
          >
            <Typography
              variant="body1"
              sx={{ color: Theme.palette.neutral.letter_Black }}
            >
              {dashboardData.name}
            </Typography>
            <Stack direction="row">
              <IconButton onClick={handleOpen}>
                <DeleteOutlinedIcon
                  sx={{ color: Theme.palette.neutral.Gray_Lit }}
                />
              </IconButton>
              <IconButton onClick={handleOpenModal}>
                <OpenInFullOutlinedIcon
                  sx={{ color: Theme.palette.neutral.Gray_Lit }}
                />
              </IconButton>
            </Stack>
          </Stack>

          <HighchartsReact options={chartOptions} highcharts={Highcharts} />
        </Rnd>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"xs"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="body2">
              Are you sure you want to delete <b>{dashboardData.name}</b>?
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <ButtonTertiaryNormal variant="text" onClick={handleClose}>
            Close
          </ButtonTertiaryNormal>
          <ButtonPrimaryNormalDelete
            variant="contained"
            onClick={(e) => handleDeleteWidget(e)}
          >
            Delete
          </ButtonPrimaryNormalDelete>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth={true}
        maxWidth={"lg"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ border: "1px solid #2294DD" }}
      >
        <DialogContent>
          <Stack justifyContent="space-between" direction="row">
            <Typography
              variant="body1"
              sx={{ color: Theme.palette.neutral.letter_Black }}
            >
              {dashboardData.name}
            </Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon sx={{ color: Theme.palette.neutral.letter_Black }} />
            </IconButton>
          </Stack>
          <br></br>
          <HighchartsReact
            options={chartOptionsModal}
            highcharts={Highcharts}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
