export const BASE_PATH = '/unicornbi' 
//export const BASE_URL = 'https://dev.unicornbi.com:443/v1'
export const BASE_URL = 'https://dev.unicornbi.com:443/v1'
//export const BASE_URL = 'https://beta.unicornbi.com:443/v1'

// let URL = ''
// if(process.env.REACT_APP_ENV === "staging") {
//     URL = 'https://beta.unicornbi.com:443/v1' ;
// }else if (process.env.REACT_APP_ENV === "qa"){
//     URL = 'https://test.unicornbi.com:443/v1';
// }else{
//     URL = 'https://dev.unicornbi.com:443/v1';
// }


// console.log(process.env.REACT_APP_API_URL)

// export const BASE_URL = process.env.REACT_APP_API_URL;
