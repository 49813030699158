import * as React from "react";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import Drawer from "@mui/material/SwipeableDrawer";
import { Typography, Stack, Divider, InputAdornment } from "@mui/material";
import FilterAltTwoToneIcon from "@mui/icons-material/FilterAltTwoTone";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
// import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import {
  FilterTextBox,
  SingleTextBox,
  StyledTextBox,
} from "../components/TextBox";
import { ActiveCard, Inactive } from "../components/Cards";
import { Theme } from "../components/Theme";
import { ThemeProvider } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export default function FilterDrawer(props) {
  const [state, setState] = useState(props.states);

  useEffect(() => {
    setState(props.states);
  }, [props]);

  return (
    <div>
      <ThemeProvider theme={Theme}>
        <Drawer
          anchor="right"
          open={state}
          onClose={() => props.changeState(false)}
          onOpen={() => {}}
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={() => props.changeState(false)}
            onKeyDown={() => props.changeState(false)}
          >
            <Stack
              direction="row"
              spacing={2}
              marginTop="5%"
              justifyContent="space-between"
            >
              <Stack
                direction="row"
                spacing={1}
                justifyContent="center"
                alignItems="center"
                marginLeft="1em"
              >
                {/* Starting of the topmost heading section on the filter navbar - FilterIcon, typography, doubleArrowicon */}
                <FilterAltTwoToneIcon />
                <Typography variant="h5" fontWeight="bold">
                  Filters
                </Typography>
              </Stack>
              <Stack
                fontSize="18"
                paddingRight="7%"
                sx={{ justifyContent: "flex-end" }}
              >
                <KeyboardDoubleArrowRightIcon />
              </Stack>
            </Stack>
            {/* End of top most heading section */}
            <br></br>
            {/* Start of search bar */}
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <StyledTextBox
                sx={{
                  height: "2.5em",
                  width: "9.3125em",
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              ></StyledTextBox>
              <Typography
                variant="body3"
                justifyContent="center"
                alignItems="center"
                sx={{ color: "primary.main" }}
              >
                ClearAll
              </Typography>
            </Stack>
            {/* end of search bar */}
            <Divider style={{ marginTop: "1em" }} /> <br></br>
            {/* <Stack justifyContent="center" alignItems="center"> */}
            {/*  Start of active card element  */}
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              marginLeft="1em"
            >
              <KeyboardArrowDownOutlinedIcon fontSize="12" />
              <Typography
                variant="body2"
                sx={{ fontColor: "#313131" }}
                fontWeight="bold"
              >
                For Selected Chart
              </Typography>
            </Stack>
            <ActiveCard>
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Typography variant="body3">Sales</Typography>
                  <KeyboardArrowDownOutlinedIcon fontSize="10" />
                </Stack>
                <VisibilityOffOutlinedIcon
                  fontSize="18"
                  sx={{ justifyContent: "flex-end" }}
                />
              </Stack>

              <Typography variant="body3" color="nuetral.letter_Black">
                Show the data when the value
              </Typography>
              <Stack JustifyContent="center" alignItems="center" spacing={1}>
                <FilterTextBox
                  defaultValue="Is less than"
                  inputProps={{ style: { fontSize: 12 } }}
                  endAdornment={
                    <InputAdornment position="end">
                      <KeyboardArrowDownOutlinedIcon />
                    </InputAdornment>
                  }
                ></FilterTextBox>
                <FilterTextBox
                  defaultValue="$ 50000"
                  inputProps={{ style: { fontSize: 12 } }}
                ></FilterTextBox>
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-end"
                color="primary.Primary_Blue"
              >
                <Typography
                  variant="body3"
                  sx={{
                    marginTop: "2em",
                  }}
                >
                  Apply
                </Typography>
              </Stack>
            </ActiveCard>
            {/*  End of active card elements  */}
            <br></br>
            {/*  Start of Inactive card element  */}
            <Inactive>
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Stack direction="row" spacing={0.3} alignItems="center">
                  <Typography variant="body3">Months</Typography>
                  <KeyboardArrowDownOutlinedIcon fontSize="10" />
                </Stack>
                <VisibilityOutlinedIcon
                  fontSize="18"
                  sx={{ justifyContent: "flex-end" }}
                />
              </Stack>
              <br></br>

              <Typography variant="body3" color="nuetral.letter_Black">
                Show data from - to
              </Typography>
              <Stack JustifyContent="center" alignItems="center" spacing={1}>
                <FilterTextBox
                  defaultValue="Jan,2021"
                  inputProps={{ style: { fontSize: 12 } }}
                  endAdornment={
                    <InputAdornment position="end">
                      <CalendarMonthOutlinedIcon />
                    </InputAdornment>
                  }
                ></FilterTextBox>
                <FilterTextBox
                  defaultValue="Dec,2021"
                  inputProps={{ style: { fontSize: 12 } }}
                  endAdornment={
                    <InputAdornment position="end">
                      <CalendarMonthOutlinedIcon />
                    </InputAdornment>
                  }
                ></FilterTextBox>
              </Stack>
              <Stack direction="row" justifyContent="flex-end" color="#C8D2D8">
                <Typography
                  variant="body3"
                  sx={{
                    marginTop: "1em",
                  }}
                >
                  Apply
                </Typography>
              </Stack>
            </Inactive>
            {/*  End of Inactive card element  */}
            <br></br>
            {/* Starting of the Location and order textbox fields */}
            <Stack marginLeft="1em" spacing={2}>
              <SingleTextBox
                defaultValue="Location"
                inputProps={{ style: { fontSize: 12 } }}
                endAdornment={
                  <InputAdornment position="end">
                    <VisibilityOffOutlinedIcon />
                  </InputAdornment>
                }
              ></SingleTextBox>
              <SingleTextBox
                defaultValue="Orders"
                inputProps={{ style: { fontSize: 12 } }}
                endAdornment={
                  <InputAdornment position="end">
                    <VisibilityOutlinedIcon />
                  </InputAdornment>
                }
              ></SingleTextBox>
            </Stack>
            {/* The Location and order textbox */}
            <br></br>
            {/* Last part of the filters section - (select chart to add filters part ) */}
            {/* Typography and icon content starting stack */}
            <Stack direction="row" spacing={1} marginLeft="1em">
              <Stack
                direction="row"
                spacing={1}
                justifyContent="center"
                alignItems="center"
              >
                <KeyboardArrowDownOutlinedIcon fontSize="10" />
                <Typography
                  variant="body2"
                  color="neutral.letter_Black"
                  fontWeight="bold"
                >
                  For all charts
                </Typography>
              </Stack>
            </Stack>{" "}
            {/* Typography and icon content ending stack */}
            {/* Start of textbox (chart selection) */}
            <Stack justifyContent="center" alignItems="center" padding="5%">
              <StyledTextBox
                sx={{ width: "12.5em", height: "4em" }}
                defaultValue="&nbsp; &nbsp; Select chart to add filters"
                inputProps={{ style: { fontSize: 12 } }}
              ></StyledTextBox>
            </Stack>
            {/* End of the textbox (chart selection) */}
            {/* </Stack> */}
          </Box>
        </Drawer>
      </ThemeProvider>
    </div>
  );
}
