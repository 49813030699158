import * as React from "react";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import {
  Divider,
  Stack,
  Typography,
  Grid,
  // InputAdornment,
  IconButton,
} from "@mui/material";
import { Theme } from "../components/Theme";
import { ThemeProvider } from "@mui/material";
import Database from "../assets/database.svg";
// import { ActiveCard } from "../components/Cards";
// import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
// import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { FilterTextBox } from "../components/TextBox";
import { StyledTab, StyledTabsv2 } from "../components/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import Visual from "./datatabs/Visual";

import TabContext from "@mui/lab/TabContext";
import Data from "./datatabs/Data";
import Personalize from "./datatabs/Personalize";
// import Advance from "./datatabs/Advance";
import KeyboardDoubleArrowDownOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowDownOutlined";
import KeyboardDoubleArrowUpOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowUpOutlined";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  getPlatformsDataHistory,
  getPlatformsStores,
} from "../services/platforms.services";
// import Moment from "moment";
import { ButtonTertiaryNormal } from "../components/Buttons";
import { postWidgetFilter, getWidgetFilter, updateWidgetFilter } from "../services/widgets.services";

export default function EditDataDrawer(props) {
  const [valueTab, setValueTabs] = useState("1");
  const [divHeight, setDivHeight] = useState("auto");
  const [platformDataHistory, setPlatformDataHistory] = useState(null);
  const [platformStores, setPlatformStores] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterData, setFilterData] = useState(null);

  const platformStoreIds =
    Object.keys(props.chartDetailes).length === 0
      ? null
      : props.chartDetailes.platformStoreIds.map((row) => row);

  let platformStoreIdsToString =
    Object.keys(props.chartDetailes).length === 0
      ? null
      : platformStoreIds.toString();

  const [tempPlatformId, setTempPlatformId] = useState(
    platformStoreIdsToString
  );


  useEffect(() => {
    (async () => {
      try {
        await getPlatformsDataHistory(platformStoreIdsToString).then((data) => {
          setPlatformDataHistory(data);
        });

        await getPlatformsStores().then((data) => {
          setPlatformStores(data);
        });

        setTempPlatformId(platformStoreIdsToString);

        await getWidgetFilter(props.chartDetailes.id).then((data) => {
          setFilterData(data);
          setStartDate(data.propertyValues[0].valuePrimary)
          setEndDate(data.propertyValues[0].valueSecondary)
        });

        
      } catch (e) {
        throw e;
      }
    })();
  }, [platformStoreIdsToString, props]);

  if (!platformDataHistory || !platformStores || !filterData) return null;

  // console.log(filterData)

  const handleChangeTabs = (event, newValue) => {
    setValueTabs(newValue);
  };



  const updatePlaformStoreId = (e, platformStoreId) => {
    // console.log(props.chartDetailes.id)

    const rowId = localStorage.getItem(props.chartDetailes.id);
    const rowIdObject = JSON.parse(rowId);

    setTempPlatformId(platformStoreId);

    // console.log({...rowIdObject, platformStoreIds: [platformStoreId]})
    localStorage.setItem("checkUpdateStatus", true);
    if (!rowId) {
      
      localStorage.setItem(
        props.chartDetailes.id,
        JSON.stringify({
          ...props.chartDetailes,
          platformStoreIds: [platformStoreId],
        })
      );
    } else {
      localStorage.setItem(
        props.chartDetailes.id,
        JSON.stringify({ ...rowIdObject, platformStoreIds: [platformStoreId] })
      );
    }
  };


  const handleFilter = (e) => {
    e.preventDefault()

    const filterValues = {
      createdType: "CUSTOMIZE",
      description: "filter by purchase date",
      name: "purchase date",
      propertyValues: [
        {
          comparator: "BETWEEN",
          filterPropertyId: '632940aab9bbf115064131c4',
          valuePrimary: startDate,
          valueSecondary: endDate,
          xaxis: true,
          yaxis: false
        }
      ],
      visible: true,
      widgetId: props.chartDetailes.id
    }

    const filterValuesUpdate = {
      createdType: "CUSTOMIZE",
      description: "filter by purchase date",
      id: filterData.id,
      name: "purchase date",
      propertyValues: [
        {
          comparator: "BETWEEN",
          filterPropertyId: '632940aab9bbf115064131c4',
          valuePrimary: startDate,
          valueSecondary: endDate,
          xaxis: true,
          yaxis: false
        }
      ],
      visible: true,
      widgetId: props.chartDetailes.id
    }

   if(Object.keys(filterData).length  <= 0){
    postWidgetFilter(filterValues).then((data) => {
      if(data.ok){
        console.log('done ok')
      }
    })
   }else{
    updateWidgetFilter(filterValuesUpdate).then((data) => {
      if(data.ok){
        console.log('done updated')
      }
    })
   }
    

  }

  return (
    <div
      style={{
        height: `${divHeight}`,
        display: Object.keys(props.chartDetailes).length === 0 ? "none" : "",
      }}
    >
      <ThemeProvider theme={Theme}>
        <Grid container>
          <Grid
            xs={2}
            sx={{
              boxShadow: "0px 4px 8px rgba(13, 51, 115, 0.24)",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              sx={{ p: 0.5 }}
              spacing={1}
            >
              {divHeight === "auto" ? (
                <IconButton onClick={(e) => {
                  setDivHeight("3em") 
                  props.callbackDrawerHeight(divHeight)
                  }}>
                  <KeyboardDoubleArrowDownOutlinedIcon />
                </IconButton>
              ) : (
                <IconButton onClick={(e) => {
                  setDivHeight("auto")
                  props.callbackDrawerHeight(divHeight)
                  }}>
                  <KeyboardDoubleArrowUpOutlinedIcon />
                </IconButton>
              )}
              <Typography variant="body2">Data Sets</Typography>
            </Stack>
            <Divider />
            <br></br>
            {/* {platformDataHistory.map((row) => 
              row.map((innerArray) => (
                <Stack
                direction="row"
                spacing={2}
                margin="5%"
                padding="5%"
                alignItems="center"
                sx={{
                  background: "rgba(186, 212, 255, 0.25)",
                  cursor: "pointer",
                }}
              >
                <img src={Database} alt="Filter Icon" />
                <Typography
                  variant="body2"
                  sx={{ textTransform: "capitalize" }}
                >
                  {innerArray.platformStore.name} / {innerArray.platformDataType.name}
                </Typography>{" "}
                <br></br>
              </Stack>
              ))
             
            )} */}
            <Box sx={{ overflowY: "auto", height: "10em" }}>
              {platformStores.map((row) => (
                <Stack
                  direction="row"
                  spacing={2}
                  margin="5%"
                  padding="5%"
                  alignItems="center"
                  sx={{
                    background:
                      tempPlatformId === row.encryptedId
                        ? "rgba(186, 212, 255, 0.25)"
                        : "",
                    cursor: "pointer",
                  }}
                  onClick={(e) => updatePlaformStoreId(e, row.encryptedId)}
                >
                  <img src={Database} alt="Filter Icon" />
                  <Typography
                    variant="body2"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {row.name} / Sales
                  </Typography>{" "}
                  <br></br>
                </Stack>
              ))}
            </Box>
          </Grid>

          <Grid xs={7}>
            <TabContext value={valueTab}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <StyledTabsv2 onChange={handleChangeTabs}>
                  <StyledTab label="Visual" value="1" />
                  <StyledTab label="Data" value="2" />
                  <StyledTab label="Personalize" value="3" />
                  {/* <StyledTab label="Advance" value="4" /> */}
                </StyledTabsv2>
              </Box>
              <TabPanel value="1">
                <Visual chartDetailes={props} />
              </TabPanel>
              <TabPanel value="2">
                <Data platformStoreIds={platformStoreIds} />
              </TabPanel>
              <TabPanel value="3">
                <Personalize chartDetailes={props} />
              </TabPanel>
              {/* <TabPanel value="4">
                <Advance />
              </TabPanel> */}
            </TabContext>
          </Grid>

          <Grid
            xs={3}
            sx={{
              boxShadow: "0px 4px 8px rgba(13, 51, 115, 0.24)",
            }}
          >
            <Stack sx={{ p: 2 }} spacing={1}>
              <Typography variant="body2">Filters</Typography>
            </Stack>
            <Divider />
            <Stack
              marginTop="5%"
              justifyContent="center"
              sx={{pl: 5, pr: 6}}
            >
              {/* <ActiveCard  sx={{pl: 1}}> */}
                <Stack
                  alignItems="flex-start"
                  sx={{pb: 1}}
                >
                    <Typography variant="body3">Date Range</Typography>
                  {/* <VisibilityOffOutlinedIcon
                    fontSize="18"
                    sx={{ justifyContent: "flex-end" }}
                  /> */}
                </Stack>
{/* 
                <Typography variant="body3" color="nuetral.letter_Black">
                  Show the data when the value
                </Typography> */}
                
                <LocalizationProvider dateAdapter={AdapterMoment} >
            <Stack spacing={1}  sx={{pb: 1}} >
              <Typography variant="body3">Start Date</Typography>
              <DesktopDatePicker
                inputFormat="MM/DD/YYYY"
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                inputProps={{ style: { fontSize: 12 } }}
                renderInput={(params) => <FilterTextBox {...params} />}
              />
            </Stack>
            <Stack spacing={1}>
              <Typography variant="body3">End Date</Typography>
              <DesktopDatePicker
                inputFormat="MM/DD/YYYY"
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                inputProps={{ style: { fontSize: 12 } }}
                renderInput={(params) => <FilterTextBox {...params} />}
              />
            </Stack>
          </LocalizationProvider>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  
                >
                  <ButtonTertiaryNormal
                    variant="text"
                    size="small"
                    sx={{color:"primary.Primary_Blue"}}
                    onClick={(e) => handleFilter(e)}
                  >
                    Apply
                  </ButtonTertiaryNormal>
                </Stack>
              {/* </ActiveCard> */}
            </Stack>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
}
