import React from "react";
import { useState, useEffect } from "react";
import {
  Stack,
  Typography,
  Grid,
  // InputAdornment,
  MenuItem,
} from "@mui/material";
// import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
// import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { StyledTextBox, StyledSelectTextBox } from "../../components/TextBox";
import { getCharts } from "../../services/charts.services";
import "./Style.css";

export default function Visual(props) {
  const [charts, setCharts] = useState(null);
  const [selectChart, setSelectChart] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        await getCharts().then((data) => {
          setCharts(data);
        });
        setSelectChart(props.chartDetailes.chartDetailes.chartTypeId);
      } catch (e) {
        throw e;
      }
    })();
  }, [props]);


  if (!charts || !selectChart) return null;


  const updateChartType = (chartTypeValue) => {
    setSelectChart(chartTypeValue);
    const rowId = localStorage.getItem(props.chartDetailes.chartDetailes.id);
    const rowIdObject = JSON.parse(rowId);

    if (!rowId) {
      localStorage.setItem(
        props.chartDetailes.chartDetailes.id,
        JSON.stringify({ ...props.chartDetailes.chartDetailes, chartTypeId: chartTypeValue})
      );
    } else {
      localStorage.setItem(
        props.chartDetailes.chartDetailes.id,
        JSON.stringify({ ...rowIdObject, chartTypeId: chartTypeValue })
      );
    }
  };

  return (
    <div className="mainBody">
      <Grid container alignItems="center" sx={{ pb: 1 }}>
        <Grid xs={3}>
          <Typography variant="body2">Visual Type</Typography>
        </Grid>
        <Grid xs={8}>
          <StyledSelectTextBox
            fullWidth
            select
            value={selectChart}
            onChange={(e) => updateChartType(e.target.value)}
          >
            {charts.map((row) => (
              <MenuItem value={row.id}>
                <Stack direction="row" spacing={3} alignItems="center">
                  <img
                    src={row.imageURLPath}
                    alt={row.displayName}
                    style={{ width: "6%" }}
                  />
                  <Typography>{row.displayName} </Typography>
                </Stack>
              </MenuItem>
            ))}
          </StyledSelectTextBox>
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ pb: 1 }}>
        <Grid xs={3}>
          <Typography variant="body2">X-axis</Typography>
        </Grid>

        <Grid xs={5}>
          <StyledTextBox fullWidth value="Sales Date" disabled />
        </Grid>

        {/* <Grid xs={3} sx={{pl:1}}>
          <Stack direction="row" spacing={1}>
            <AddOutlinedIcon fontSize="12" color="primary" />
            <Typography variant="body2" color="primary">
              Add Field
            </Typography>
          </Stack>
        </Grid> */}
      </Grid>

      <Grid container alignItems="center" sx={{ pb: 1 }}>
        <Grid xs={3}>
          <Typography variant="body2">Y-axis</Typography>
        </Grid>
        <Grid xs={5}>
          <StyledTextBox fullWidth value="Sales (USD)" disabled />
        </Grid>

        {/* <Grid xs={3} sx={{pl:1}}>
          <Stack direction="row" spacing={1}>
            <AddOutlinedIcon fontSize="12" color="primary" />
            <Typography variant="body2" color="primary">
              Add Field
            </Typography>
          </Stack>
        </Grid> */}
      </Grid>

      <Grid container alignItems="center" sx={{ pb: 1 }}>
        <Grid xs={3}>
          <Typography variant="body2">Tool Tip</Typography>
        </Grid>
        <Grid xs={5}>
          <Stack direction="row">
            <StyledTextBox fullWidth value="Sales" disabled />
          </Stack>
        </Grid>
        {/* 
        <Grid item xs={3} sx={{pl:1}}>
          <Stack direction="row" spacing={1}>
            <AddOutlinedIcon fontSize="12" color="primary" />
            <Typography variant="body2" color="primary">
              Add Field
            </Typography>
          </Stack>
        </Grid> */}
      </Grid>
    </div>
  );
}
