import * as React from 'react';
import { AppBar, Box, Toolbar, Grid} from '@mui/material/';
// import PersonIcon from '@mui/icons-material/Person';
import MainLogo from "../../../assets/fulllogo.svg";


export default function MenuBar() {
  return (
    <Box sx={{ flexGrow: 1}}>
      <AppBar position="static" sx={{backgroundColor: '#fff', boxShadow: ['none'], borderBottom: '1px solid #CED0D2' }}>
        <Toolbar> 
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <img src={MainLogo} alt="Logo" />
            {/* <Avatar sx={{ bgcolor: '#BAD4FF' }}><PersonIcon fontSize="large"/></Avatar> */}
            </Grid> 
        </Toolbar>
      </AppBar>
    </Box>
  );
}
