import * as React from "react";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { StyledTabsv3, StyledTab } from "../../components/Tabs";
import { StyledCard } from "../../components/Cards";
import ProfileSettings from "./ProfileSettings";
import CompanySettings from "./CompanySettings";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';





export default function Settings() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <StyledCard
      sx={{
        padding: "1% 1% 1% 2%",
        borderShadow: "0px 5px 10px rgba(0, 0, 0, 0.05)",
        borderRadius: "0px 0px 8px 8px",
        mt: 3,
        //  height:"50.625em"
      }}
    >
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
         
            <StyledTabsv3
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              
              <StyledTab variant="body2" label="Profile Settings" value="1" iconPosition="start" icon={<PersonOutlineOutlinedIcon/>}/>
              <StyledTab variant="body2" label="Company Settings" value="2" iconPosition="start" icon={<ApartmentOutlinedIcon/>}/>
            </StyledTabsv3>
        
          <TabPanel value="1">
            <ProfileSettings />
          </TabPanel>

          <TabPanel value="2">
            <CompanySettings />
          </TabPanel>
        </TabContext>
      </Box>
    </StyledCard>
  );
}
