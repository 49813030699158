import {
  BrowserRouter,
  Route,
  Routes,
  // Navigate
} from "react-router-dom";
// import Routes from './Routes';
// import { BASE_PATH } from "./utils/constants";
import DashboardNavigation from "./layouts/DashboardNavigation";
import StepOne from "./view/SignIn/StepOne";
import StepTwo from "./view/SignIn/StepTwo";
import ForgotPassword from "./view/SignIn/ForgotPassword";
import CreateAccount from "./view/SignUp/CreateAccount";
import OnBoarding from "./view/SignUp/Onboarding";
import Dashboard from "./view/Dashboard";
import { ApolloProvider } from '@apollo/client';
import Navigation from "./layouts/Navigation";
import EditNavigation from "./layouts/EditNavigation";
import EditDashboard from "./view/Dashboard/EditDashboard";
import {client} from "./ApolloClient/client";
import UnicornHub from "./view/UnicornHub";
import { ThemeProvider } from "@mui/material/styles";
import { Theme } from "./components/Theme";
import HelpAndSupport from "./view/HelpAndSupport";
import Settings from "./view/Settings";
import ConfirmSignin from "./view/SignIn/ConfirmSignin";
function App() {
  return (
    <ThemeProvider theme={Theme}>
      <ApolloProvider client={client}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigation />}>
            <Route index element={<UnicornHub />} />
            <Route path="/unicorn-hub" element={<UnicornHub />} />
            <Route path="/help-and-support" element={<HelpAndSupport/>}/>
            <Route path="/settings" element={<Settings />} />
          </Route>

          <Route path="/dashboard" element={<DashboardNavigation />}>
            <Route path="/dashboard" element={<Dashboard />} />   
          </Route>
          
          <Route path="/edit-dashboard" element={<EditNavigation />}>
            <Route path="/edit-dashboard" element={<EditDashboard />} />
          </Route>

          
          <Route path="/signin" element={<StepOne />} />
          <Route path="/magic/:id" element={<ConfirmSignin />} />
          <Route path="/enter-password" element={<StepTwo />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/onboarding" element={<OnBoarding />} />

          {/* <Navigate exact from="/" to="signin"></Navigate> */}
        </Routes>
      </BrowserRouter>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
