import { styled } from "@mui/material/styles";
// import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import { TabList } from "@mui/lab";

export const StyledTabs = styled(TabList)(({ theme }) => ({
  "& .MuiTabs-flexContainer": {
    display: "flex",
    alignItems: "center",
  },
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: theme.palette.primary.Primary_Blue,
  },
}));

export const StyledTabsv2 = styled(TabList)(({ theme }) => ({
  "& .MuiTabs-flexContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: theme.palette.primary.Primary_Blue,
  },
}));

export const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  padding: '0 !important'
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: ["none"],
  // fontWeight: theme.typography.fontWeightRegular,
  // fontSize: theme.typography.pxToRem(15),
  // marginRight: theme.spacing(1),
  color: theme.palette.neutral.Gray_Dk,
  "&.Mui-selected": {
    color: theme.palette.primary.Primary_Blue,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

export const StyledTabsv3 = styled(TabList)(({ theme }) => ({
  "& .MuiTabs-flexContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: theme.palette.primary.Primary_Blue,
  },
}));
