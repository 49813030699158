import * as React from "react";
import { useEffect, useState } from "react";
import {
  Box,
  CssBaseline,
  Toolbar,
  Typography,
  Stack,
  ThemeProvider,
  Avatar,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";

// import {
//   NotificationsNoneOutlined,
//   AccountCircleRounded,
//   SearchOutlined,
// } from "@mui/icons-material";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";

// import { AnimatedSearchBar } from "../components/TextBox";
import MainLogo from "../assets/fulllogo.svg";
import { Theme } from "../components/Theme";
import ChartTypeDrawer from "./ChartTypeDrawer";
import SubNavigation from "./SubNavigation";
import EditDataDrawer from "./EditDataDrawer";
import { getUserDetails } from "../services/users.services";
import { EditDrawer, AppBar, DrawerHeader } from "../components/Drawer";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PlatformStores from "./PlatformStores";

import { StyledTab, StyledTabs, StyledTabPanel } from "../components/Tabs";

const drawerWidth = 240;

export default function EditNavigation(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { dashboardId, dashboardsName, collectionId } = location.state;
  const [currentValue, setCurrentValue] = useState({});
  const [chartDetailes, setChartDetails] = useState({});
  const [userDetails, setUserDetails] = useState("");
  const [value, setValue] = useState("1");
  const [drawerHeight, setDrawerHeight] = useState("auto")

  const callback = (data) => {
    setCurrentValue(data);
  };

  const callbackDrawerHeight = (data) => {
    setDrawerHeight(data);
  };

  const callBackChartDetails = (data) => {
    setChartDetails(data);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      navigate("/signin");
    }
    (async () => {
      try {
        await getUserDetails().then((data) => {
          setUserDetails(data);
          if (data === undefined || data === null) {
            localStorage.clear();
            navigate("/signin");
          }
          localStorage.setItem("organizationId", data.organization.encryptedId);
        });
      } catch (e) {
        throw e;
      }
    })();
    // eslint-disable-next-line
  }, []);

  if (!userDetails) return null;

  const drawer = (
    <div>
      <DrawerHeader sx={{ justifyContent: "flex-start", px: 3 }}>
        <NavLink to="/unicorn-hub">
          <img src={MainLogo} alt="Logo" />
        </NavLink>
      </DrawerHeader>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <StyledTabs onChange={handleChange}>
            <NavLink
              to="/dashboard"
              state={{ dashboardId, dashboardsName, collectionId }}
            >
              <ArrowBackIcon sx={{ fontSize: 24, color: "#4F5F6C" }} />
            </NavLink>

            <StyledTab
              value="1"
              label="Visuals"
              sx={{ textTransform: "none" }}
            />
            <StyledTab
              value="2"
              label="Source"
              sx={{ textTransform: "none" }}
            />
          </StyledTabs>
        </Box>
        <StyledTabPanel value="1">
          <ChartTypeDrawer callBack={callback} />
        </StyledTabPanel>
        <StyledTabPanel value="2">
          <PlatformStores />
        </StyledTabPanel>
      </TabContext>
    </div>
  );

  return (
    <ThemeProvider theme={Theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <EditDrawer variant="permanent" open={true}>
          {drawer}
        </EditDrawer>

        <AppBar
          justifyContent="space-between"
          position="fixed"
          open={true}
          sx={{ boxShadow: ["none"], zIndex: 1 }}
        >
          <Toolbar>
            <Stack
              width="100%"
              justifyContent="space-between"
              direction="row"
              alignItems="center"
            >
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                Business Analytics
              </Typography>

              {/* right end navigation elements search bar, notification, AccountCircle*/}
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="flex-end"
              >
                {/* <AnimatedSearchBar
                  placeholder="Search"
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchOutlined
                        sx={{
                          position: "absolute",
                          right: 2,
                          color: "#FFFFFF",
                        }}
                        edge="end"
                      ></SearchOutlined>
                    </InputAdornment>
                  }
                />
                <NotificationsNoneOutlined />
                <AccountCircleRounded /> */}

                <NavLink to="/settings" sx={{ textDecoration: "none" }}>
                  <Avatar
                    alt={userDetails.user.firstName}
                    src={userDetails.user.imageUrlPath}
                  />
                </NavLink>
              </Stack>
            </Stack>
          </Toolbar>
          <SubNavigation />
        </AppBar>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <br></br>
          <Toolbar />
          <Outlet context={[currentValue, callBackChartDetails, drawerHeight]} />
        </Box>

        <AppBar
          position="fixed"
          color="default"
          open={true}
          sx={{
            top: "auto",
            bottom: 0,
            boxShadow: ["none"],
            zIndex: 1,
            backgroundColor: "#fff",
          }}
        >
          <EditDataDrawer chartDetailes={chartDetailes} callbackDrawerHeight={callbackDrawerHeight}/>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
}
