import * as React from "react";
import { useState, useEffect } from "react";
import {
  CardContent,
  Typography,
  Grid,
  Stack,
  InputAdornment,
  IconButton,
  Snackbar,
  Button
} from "@mui/material";
import "./style.css";
import MainLogo from "../../assets/fulllogo.svg";
import { ContainedButton } from "../../components/Buttons";
import { StyledCard } from "../../components/Cards";
import { StyledTextBox } from "../../components/TextBox";
// import KeyIcon from '@mui/icons-material/Key';
import { NavLink, useNavigate, useParams } from "react-router-dom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import PasswordStrengthBar from "react-password-strength-bar";
import { getUserDetails } from "../../services/users.services";
import { passwordReset } from "../../services/users.services";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";



export default function ConfirmSignin() {
  let { id } = useParams();
  // console.log(id)
  localStorage.setItem("accessToken", id);
  const [email, setUserEmail] = useState("");
  //   window.location = "/onboarding";
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorCheckPassword, setErrorCheckPassword] = useState(false);
  const [errorCheckPasswordConfirm, setErrorCheckPasswordConfirm] =
    useState(false);
  const [passwordMessage, setPasswordMessage] = useState("");
  const [passwordConfirmMessage, setPasswordConfirmMessage] = useState("");

  const navigate = useNavigate();
  const [openFail, setOpenFail] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        await getUserDetails().then((data) => {
          setUserEmail(data.user.email);
        });
      } catch (e) {
        throw e;
      }
    })();
  }, []);

  if (!email) return null;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // setOpenSuccess(false);
    setOpenFail(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const value = {
        email: email,
        password: password,
      };
      
    let error = false;
    var letters = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
    );

    if (password === "" || password === null) {
      error = true;
      setErrorCheckPassword(true);
    } else {
      setErrorCheckPassword(false);
    }

    if (password.length < 8) {
      error = true;
      setErrorCheckPassword(true);
      setPasswordMessage("Passwords length need to be more than 8 Characters");
    } else {
      setErrorCheckPassword(false);
      setPasswordMessage("");
    }

    if (!letters.test(password)) {
      error = true;
      setErrorCheckPassword(true);
      setPasswordMessage(
        "To become strong password, it must contain number, special character and capital letters"
      );
    } else {
      setErrorCheckPassword(false);
      setPasswordMessage("");
    }

    if (passwordConfirm !== password) {
      error = true;
      setPasswordConfirmMessage("Passwords don't match");
      setErrorCheckPasswordConfirm(true);
    } else {
      setPasswordConfirmMessage("");
      setErrorCheckPasswordConfirm(false);
    }

  

    if (!error) {
      passwordReset(value).then((data) => {
        if (data.ok) {
            // window.location = "/onboarding";
            setOpenSuccess(true);
            setTimeout(() => navigate("/onboarding"), 2000);
        }else{
            handleClick()
        }
      });
    }
  };

  const handleClick = () => {
    setOpenFail(true);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="main">
      <Grid container spacing={2}>
        <Grid
          container
          direction="column"
          justifyContent="flex-end"
          alignItems="center"
        >
          <StyledCard className="cardMain">
            <CardContent>
              <Stack spacing={2}>
                <img src={MainLogo} alt="Logo" style={{ width: "8.36375em" }} />
                <br></br>
                <Typography variant="h4" color="#1D1E30">
                  <b>Reset Password</b>
                </Typography>
                <Stack spacing={1}>
                  <Typography variant="body2">{email} </Typography>
                  <StyledTextBox
                    error={errorCheckPassword ? true : false}
                    fullWidth
                    placeholder="New Password"
                    id="outlined-adornment-weight"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOutlinedIcon />
                          ) : (
                            <VisibilityOffOutlinedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {password.length <= 0 ? (
                    ""
                  ) : (
                    <PasswordStrengthBar
                      password={password}
                      shortScoreWord=""
                      scoreWords=""
                      minLength="1"
                    />
                  )}
                  <Typography variant="body3" color="red">
                    {passwordMessage}
                  </Typography>
                  <StyledTextBox
                    error={errorCheckPasswordConfirm ? true : false}
                    fullWidth
                    placeholder="Confirm New Password"
                    id="outlined-adornment-weight"
                    value={passwordConfirm}
                    type={showConfirmPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOutlinedIcon />
                          ) : (
                            <VisibilityOffOutlinedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                  />
                  <Typography variant="body3" color="red">
                    {passwordConfirmMessage}
                  </Typography>
                </Stack>
                <br></br>
                <br></br>
                <ContainedButton variant="contained" onClick={(e) => handleSubmit(e)}>
                  Update Password
                </ContainedButton>
                <NavLink to="/onboarding" className="buttonLinks">
                  <Typography
                    variant="body2"
                    color="#2294DD"
                    textAlign="center"
                  >
                    <b>Do this later</b>
                  </Typography>
                </NavLink>
              </Stack>
            </CardContent>
          </StyledCard>
          {/* <br></br>
        <StyledCard className="cardBottom">
          <CardContent>
              <Stack direction="row" spacing={2} alignItems="center">
                <KeyIcon fontSize="large" style={{transform: 'rotate(320deg)'}}/>
            <Typography variant="h4"><b>Sign in with SSO</b></Typography>
            </Stack>
          </CardContent>
        </StyledCard> */}
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="flex-end"
          style={{ marginRight: "1%", marginBottom: "2%" }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="body3" color="#004B8B">
              Terms of Service
            </Typography>
            <Typography variant="body3" color="#004B8B">
              Privacy
            </Typography>
            {/* <Stack direction="row" spacing={1} alignItems="center">
            <LanguageIcon sx={{ color:"#004B8B"}}/>
          <Typography variant="body3" color="#004B8B">
          Change region
          </Typography>
            <KeyboardArrowDownIcon sx={{ color:"#004B8B"}}/>
          </Stack> */}
          </Stack>
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        // severity="error"
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleClose}
        // message="Note archived"
        action={action}
      >
        <Alert severity="success">
          <b>Password Update! </b>
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        // severity="error"
        open={openFail}
        autoHideDuration={6000}
        onClose={handleClose}
        // message="Note archived"
        action={action}
      >
        <Alert severity="error">
          <b>Oops! </b>
          Something went wrong
        </Alert>
      </Snackbar>
    </div>
  );
}
