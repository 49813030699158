// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/background.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.main {\n    min-height: 100vh;\n    display: flex;\n    color: white;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n   \n}\n\n.cardMain {\n    width: 35%;\n    padding: 2%;\n    max-width: 500px;\n\n}\n\n.cardBottom{\n    width: 37%;\n    padding: 0 0 0 2%;\n}\n\n.buttonLinks{\n    text-decoration: none;\n    \n}", "",{"version":3,"sources":["webpack://./src/view/SignIn/style.css"],"names":[],"mappings":";AACA;IACI,iBAAiB;IACjB,aAAa;IACb,YAAY;IACZ,yDAAoD;;AAExD;;AAEA;IACI,UAAU;IACV,WAAW;IACX,gBAAgB;;AAEpB;;AAEA;IACI,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;;AAEzB","sourcesContent":["\n.main {\n    min-height: 100vh;\n    display: flex;\n    color: white;\n    background-image: url('../../assets/background.svg');\n   \n}\n\n.cardMain {\n    width: 35%;\n    padding: 2%;\n    max-width: 500px;\n\n}\n\n.cardBottom{\n    width: 37%;\n    padding: 0 0 0 2%;\n}\n\n.buttonLinks{\n    text-decoration: none;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
