import React from "react";
import { useState } from "react";
// import DevicesIcon from "@mui/icons-material/Devices";
// import StarIcon from "@mui/icons-material/Star";
// import Avatar from "@mui/material/Avatar";
import FilterDrawer from "./FilterDrawer";
import { ButtonTertiaryNormal } from "../components/Buttons";
import { Box, Stack, ThemeProvider, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";

import Dashboard from "../assets/dashboard.svg";
import Edit from "../assets/edit.svg";
// import Share from "../assets/share.svg";
import Filter from "../assets/filter.svg";
// import { GreenAvatarGroup } from "../components/Avatar";
import { Theme } from "../components/Theme";
import { NavLink } from "react-router-dom";
import "../assets/style.css";

export default function SubNavigation() {
  const location = useLocation();
  const { dashboardId, dashboardsName, collectionId } = location.state;
  const [filterState, setFilterState] = useState(false);

  return (
    <ThemeProvider theme={Theme}>
      <Box
        sx={{
          backgroundColor: "neutral.Pure_White_Bg",
          boxShadow: "0px 2px 8px rgba(13, 51, 115, 0.1)",
          height: 48,
          p: 1,
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <Stack direction="row" justifyContent="flex-start">
              <NavLink to="/dashboard" state={{ dashboardId, dashboardsName, collectionId }} className={({ isActive }) =>
              isActive ? "activeButton" : "inActiveButton"
            }>
              <ButtonTertiaryNormal
                variant="text"
                startIcon={<img src={Dashboard} alt="Dasboard Icon" />}
              >
                {" "}
                Dashboard
              </ButtonTertiaryNormal>
              </NavLink>
              {/* <ButtonTertiaryNormal
                variant="text"
                startIcon={<img src={Share} alt="Share Icon" />}
              >
                Share
              </ButtonTertiaryNormal> */}

              <ButtonTertiaryNormal
                variant="text"
                onClick={() => setFilterState(true)}
                startIcon={<img src={Filter} alt="Filter Icon" />}
              >
                Filter
              </ButtonTertiaryNormal>

              <NavLink to="/edit-dashboard"  state={{ dashboardId, dashboardsName, collectionId }}  className={({ isActive }) =>
              isActive ? "activeButton" : "inActiveButton"
            }>
                <ButtonTertiaryNormal
                  variant="text"
                  startIcon={<img src={Edit} alt="Edit Icon" />}
                >
                  Edit
                </ButtonTertiaryNormal>
              </NavLink>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            {/* <Stack
              direction="row"
              spacing={1}
              justifyContent="center"
              alignItems="center"
            >
              <GreenAvatarGroup>
                <Avatar alt="Travis Howard" sx={{ height: 30, width: 30 }} />
              </GreenAvatarGroup>
            </Stack> */}
          </Grid>
          <Grid item xs={4}>
            {/* <Stack direction="row" spacing={2} justifyContent="flex-end">
              <StarIcon
                sx={{ color: "warning.main" }}
                style={{ fontSize: 20 }}
              />
              <DevicesIcon
                sx={{ color: "neutral.Gray_Dk" }}
                style={{ fontSize: 20 }}
              />
            </Stack> */}
          </Grid>
        </Grid>
      </Box>

      <FilterDrawer
        states={filterState}
        changeState={(value) => setFilterState(value)}
      />
    </ThemeProvider>
  );
}
