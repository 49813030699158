import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./Style.css";
import { gql, useQuery } from "@apollo/client";

export default function Data(props) {
  
  const platformStoreIds =
    Object.keys(props.platformStoreIds).length === 0
      ? null
      : props.platformStoreIds.map((row) => row);

  let platformStoreIdsToString =
    Object.keys(props.platformStoreIds).length === 0
      ? null
      : platformStoreIds.toString();

  const QUERY = gql`
  {
    findByPlatformStoreId(
      platformStoreId: "${platformStoreIdsToString}"
      platformDataType: SALES
    ) {
      sales {
        purchaseDate
        totalSalesAmount
        totalDiscountAmount
        totalTaxAmount
        currency
        totalOrderItemsQty
      }
    }
  }
`;

  const { data } = useQuery(QUERY);

  if (!data) return null;


  return (
    <div className="mainBody">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ border: "1px solid #C8D2D8" }}>
            <TableRow sx={{ backgroundColor: "#F6F6F6" }}>
              <TableCell sx={{ border: "1px solid #C8D2D8" }} width="18%">
                Purchase Date
              </TableCell>
              <TableCell sx={{ border: "1px solid #C8D2D8" }} width="5%">
                Currency
              </TableCell>
              <TableCell sx={{ border: "1px solid #C8D2D8" }} width="23%">
                Discount Amount
              </TableCell>
              <TableCell sx={{ border: "1px solid #C8D2D8" }} width="5%">
                Qty
              </TableCell>
              <TableCell sx={{ border: "1px solid #C8D2D8" }} width="18%">
                Sales Amount
              </TableCell>
              <TableCell sx={{ border: "1px solid #C8D2D8" }} width="18%">
                Tax Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.findByPlatformStoreId.sales.map((row, index) => (
              <TableRow key={index} sx={{ border: "1px solid" }}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ border: "1px solid #C8D2D8" }}
                >
                  {row.purchaseDate}
                </TableCell>
                <TableCell sx={{ border: "1px solid #C8D2D8" }}>
                  {row.currency}
                </TableCell>
                <TableCell sx={{ border: "1px solid #C8D2D8" }} align="right">
                  {row.totalDiscountAmount}
                </TableCell>
                <TableCell sx={{ border: "1px solid #C8D2D8" }} align="right">
                  {row.totalOrderItemsQty}
                </TableCell>
                <TableCell sx={{ border: "1px solid #C8D2D8" }} align="right">
                  {row.totalSalesAmount}
                </TableCell>
                <TableCell sx={{ border: "1px solid #C8D2D8" }} align="right">
                  {row.totalTaxAmount}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
