import React from "react";
import CreateTicket from "./CreateTicket";


export default function index() {

  
  return (
    <div>
       <CreateTicket />

      


    </div>
  );
}
