import { Typography, Stack, Box, Grid, Alert } from "@mui/material";
import React, { useState } from "react";
import {
  StyledTextBox,
  StyledTextArea,
  StyledSelectTextBox,
} from "../../components/TextBox";
import { ButtonPrimaryNormal } from "../../components/Buttons";
import { StyledCard } from "../../components/Cards";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import HelpAndSupport from "../../assets/helpandsupport.svg";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { postSupportTicket } from "../../services/support.services";

export default function CreateTicket() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "33.75em",
    height: "36.25em",
    borderRadius: "8px",
    boxShadow: "0px 5px 25px 2px rgba(49, 49, 49, 0.25)",
    p: 4,
    background: "#FFFFFF",
  };

  const [catergory, setCatergory] = useState("DASHBOARD_ISSUE");
  const [open, setOpen] = useState(false);

  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");

  const [errorCheckSubject, setErrorCheckSubject] = useState(false);
  const [errorCheckDescription, setErrorCheckDescription] = useState(false);

// const [enteredTitle, setEnteredTitle] = useState("");
//  const [enteredPrice, setEnteredPrice] = useState("");
//  const [enteredDate, setEnteredDate] = useState("");

  const [alert, setAlert] = useState(false);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // handleSubmit.resetForm()
    

    if (subject === "") {
      setErrorCheckSubject(true);
      setAlert(true);
    }
    if (description === "") {
      setErrorCheckDescription(true);
      setAlert(true);
    }

    const value = {
      attachmentUrlPath: "string",
      issueType: catergory,
      ticketDescription: description,
      ticketSubject: subject,
    };
    if (subject !== "" || description !== "") {
      postSupportTicket(value).then((data) => {
        if (data.ok) {
          setOpen(true);
          setSubject("");
          setDescription("") 
        }
      });
    }
  };
  const handleClose = () => setOpen(false);

  const action = (
    <React.Fragment>
      {/* <Button color="secondary" size="small" handleClickAlert={handleCloseAlert}>
        UNDO
      </Button> */}

      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        handleClickAlert={handleCloseAlert}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <StyledCard
      sx={{
        padding: "2% 1% 1% 2%",
        mt: 3,
        borderShadow: "0px 5px 10px rgba(0, 0, 0, 0.05)",
        borderRadius: "0px 0px 8px 8px",
      }}
    >
      <Grid container>
        <Grid xs={12} md={8}>
          <Typography variant="h3" paddingTop="1%">
            {" "}
            Tell us more about your issue
          </Typography>
          <Typography variant="body1">
            Please feel free to share your issue with us and we will contact you
            as soon as possible to resolve it .
          </Typography>

          <Typography variant="body1" paddingTop="2%" fontWeight="bold">
            Subject for issue
          </Typography>
          <br></br>
          <StyledTextBox
            fullWidth
            placeholder="Ticket subject here"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            error={errorCheckSubject ? true : false}
          ></StyledTextBox>

          <Typography variant="body1" paddingTop="2%" fontWeight="bold">
            Issue Category
          </Typography>
          <br></br>
          <StyledSelectTextBox
            fullWidth
            placeholder="Ticket subject here"
            select
            value={catergory}
            onChange={(e) => setCatergory(e.target.value)}
          >
            <MenuItem value="DASHBOARD_ISSUE">Dashboard</MenuItem>
            <MenuItem value="WIDGET_ISSUE">Widget</MenuItem>
            <MenuItem value="DATA_FILTER_ISSUE">Data Filter</MenuItem>
            <MenuItem value="CHART_ISSUE">Charts</MenuItem>
            <MenuItem value="CONNECTING_APPS_ISSUE">Application Connection</MenuItem>
            <MenuItem value="OTHER">Other</MenuItem>
            
          </StyledSelectTextBox>

          <Typography variant="body1" paddingTop="2%" fontWeight="bold">
            Describe your issue
          </Typography>
          <br></br>
          <StyledTextArea
            aria-label="minimum height"
            minRows={3}
            placeholder="Minimum 3 rows"
            multiline
            fullWidth
            inputProps={{ maxLength: 200 }}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            error={errorCheckDescription ? true : false}
          />
          {/* <Typography variant="body1" paddingTop="2%" fontWeight="bold">
            Attachments
          </Typography>
          <br></br>
          <StyledTextBox fullWidth></StyledTextBox> */}

          <Stack justifyContent="flex-end" direction="row" marginTop="2%">
            <ButtonPrimaryNormal onClick={(e) => handleSubmit(e)}>
              Send request
            </ButtonPrimaryNormal>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  variant="h3"
                  textAlign="center"
                >
                  Will Contact You Soon!
                </Typography>{" "}
                <br></br>
                <img src={HelpAndSupport} alt="Filter Icon" />
                <Typography
                  id="modal-modal-description"
                  variant="body2"
                  textAlign="center"
                  sx={{ mt: 2, lineHeight: "28px" }}
                >
                  Thank you for contacting us, please hold on. One of our agents
                  will contact you soon regarding your issue <b>(Ref:000001)</b>
                  <br></br>
                  sorry for the inconvenience occurred.
                </Typography>
                <br></br>
                <Stack alignItems="center">
                  <ButtonPrimaryNormal
                    onClick={handleClose}
                    sx={{ width: "9.75em", marginTop: "6%" }}
                  >
                    OK
                  </ButtonPrimaryNormal>
                </Stack>
              </Box>
            </Modal>
          </Stack>
        </Grid>
      </Grid>

      <Snackbar
        open={alert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        // message="Please fill the required fields"
        action={action}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        // anchorOrigin={{ vertical, horizontal }}
        // key={vertical + horizontal}
      >
        <Alert
          onClose={handleCloseAlert}
          severity="error"
          sx={{ backgroundColor: "#FEEFEE" }}
        >
          Please fill the required fields
        </Alert>
      </Snackbar>

      <br></br>
    </StyledCard>
  );
}
