import * as React from "react";
import { useState } from "react";
import {
  CardContent,
  Typography,
  Grid,
  Stack,
  Button,
  Snackbar,
  IconButton
} from "@mui/material";
import "./style.css";
import MainLogo from "../../assets/fulllogo.svg";
import { ContainedButton, TextButton } from "../../components/Buttons";
import { StyledCard } from "../../components/Cards";
import { StyledTextBox } from "../../components/TextBox";
// import KeyIcon from '@mui/icons-material/Key';
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { passwordResetLink } from "../../services/users.services";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import EmailConfirm from "../../assets/emailConfirm.svg";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import validator from "validator";


export default function ForgotPassword() {
  const location = useLocation();
  const { siginEmail } = location.state;
  const [email, setEmail] = useState(siginEmail);

  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const [errorCheckEmail, setErrorCheckEmail] = useState(false);
  const [emailMessage, setEmailMessage] = useState("");


  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // setOpenSuccess(false);
    setOpen(false);
  };

  const handleRequest = () => {
    let error = false;
    const value = {
      email: email,
      resetPasswordType: "MAGIC_LINK",
    };

    if (validator.isEmail(email)) {
      setEmailMessage("");
      setErrorCheckEmail(false);
    } else {
      setEmailMessage("Enter a valid email");
      setErrorCheckEmail(true);
      error = true;
    }

    if(!error){
      passwordResetLink(value).then((data) => {
        if (data.ok) {
          setOpenSuccess(true)
          setOpenModal(true)
          setEmailMessage("")
          setErrorCheckEmail(false);
        } else if(data.status === 404){
          setEmailMessage("Email does not exists")
          setErrorCheckEmail(true)
        }else {
          setOpen(true);
        }
      });
    }
    
  };


  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="main">
      <Grid container spacing={2}>
        <Grid
          container
          direction="column"
          justifyContent="flex-end"
          alignItems="center"
        >
          <StyledCard className="cardMain">
            <CardContent>
              <Stack spacing={2}>
                <img src={MainLogo} alt="Logo" style={{ width: "8.36375em" }} />
                <br></br>
                <Typography variant="h4" color="#1D1E30">
                  <b>Forget Password</b>
                </Typography>
                <Stack spacing={1}>
                  <Typography variant="body2">
                    We’ll email you a magic code for a password-free sign in.
                    Please enter your email{" "}
                  </Typography>
                  <StyledTextBox
                  error={errorCheckEmail ? true : false}
                    fullWidth
                    placeholder="Email"
                    id="outlined-adornment-weight"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                    <Typography variant="body3" color="red">
                    {emailMessage}
                  </Typography>
                </Stack>
                <br></br>
                <br></br>
                <ContainedButton variant="contained" onClick={handleRequest}>
                  Request Magic Link
                </ContainedButton>
                <NavLink to="/signin" className="buttonLinks">
                  <Typography
                    variant="body2"
                    color="#2294DD"
                    textAlign="center"
                  >
                    <b>Go Back</b>
                  </Typography>
                </NavLink>
              </Stack>
            </CardContent>
          </StyledCard>
          {/* <br></br>
        <StyledCard className="cardBottom">
          <CardContent>
              <Stack direction="row" spacing={2} alignItems="center">
                <KeyIcon fontSize="large" style={{transform: 'rotate(320deg)'}}/>
            <Typography variant="h4"><b>Sign in with SSO</b></Typography>
            </Stack>
          </CardContent>
        </StyledCard> */}
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="flex-end"
          style={{ marginRight: "1%", marginBottom: "2%" }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="body3" color="#004B8B">
              Terms of Service
            </Typography>
            <Typography variant="body3" color="#004B8B">
              Privacy
            </Typography>
            {/* <Stack direction="row" spacing={1} alignItems="center">
            <LanguageIcon sx={{ color:"#004B8B"}}/>
          <Typography variant="body3" color="#004B8B">
          Change region
          </Typography>
            <KeyboardArrowDownIcon sx={{ color:"#004B8B"}}/>
          </Stack> */}
          </Stack>
        </Grid>
      </Grid>

      <Dialog
        fullScreen={fullScreen}
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogContentText sx={{ p: 2 }}>
            <Stack justifyContent="center" alignItems="center">
              <br></br>
              <Stack spacing={1} textAlign="center">
                <Typography variant="h4">
                  We’ve sent the <b>Magic link</b> to your email.
                </Typography>
                <Typography variant="body2">
                  Please check your inbox or junk folder for cofirmation email.
                </Typography>
              </Stack>
              <img
                src={EmailConfirm}
                alt="Email Confirm"
                style={{ width: "60%" }}
              />
              <br></br>
            </Stack>{" "}
          </DialogContentText>
        </DialogContent>
        <Stack justifyContent="flex-end" alignItems="center">
          <TextButton variant="text" onClick={handleCloseModal}>
            Ok
          </TextButton>
        </Stack>
        <br></br>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Disagree
          </Button>
          <Button onClick={(e) => handleSubmit(e)}>Agree</Button>
        </DialogActions> */}
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        // severity="error"
        open={openSuccess}
        autoHideDuration={3000}
        onClose={handleClose}
        // message="Note archived"
        action={action}
      >
        <Alert severity="success">
          <b>Magic link succccesfully sent!</b>
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        // severity="error"
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        // message="Note archived"
        action={action}
      >
        <Alert severity="error">
          <b>Oops! </b>
          Something went wrong
        </Alert>
      </Snackbar>
    </div>
  );
}
