import React, { useState, useEffect } from "react";
import { Typography, Stack, Box } from "@mui/material";
// import { getData } from "../../services/dashboard.services";
// import { getPlatformsStores } from "../../services/platforms.services";

import { RedoOutlined } from "@ant-design/icons";
import BasicChart from "../../components/Charts/BasicCharts";
import { getDashboardWidgets } from "../../services/dashboard.services";
import { NavLink, useLocation } from "react-router-dom";
import { ButtonPrimaryNormal } from "../../components/Buttons";
import Edit from "../../assets/edit.svg";

export default function Dashboard() {
  const location = useLocation();
  const { dashboardId, dashboardsName, collectionId } = location.state;
  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        await getDashboardWidgets(dashboardId).then((data) => {
          setDashboardData(data);
        });
      } catch (e) {
        throw e;
      }
    })();
  }, [dashboardId]);

  if (!dashboardData) return null;

  return (
    <div style={{ marginTop: "1%" }}>
      <Stack justifyContent="space-between" direction="row" alignItems="center" sx={{pb:1}}>
        <Stack direction="row" spacing={1}>
          <Typography
            variant="h4"
            color="neutral.Gray_Dk"
            style={{ justifyContent: "center" }}
          >
            Shopify /
          </Typography>
          <Typography
            variant="h4"
            color="neutral.Black_Lit"
            style={{ justifyContent: "center" }}
          >
            {dashboardsName}
          </Typography>
        </Stack>

        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-end"
        >
          <RedoOutlined sx={{ fontSize: 18}} />{" "}
          <Typography variant="body2">Updated on: 12hr ago</Typography>
        </Stack>
      </Stack>
      {dashboardData.length <= 0 ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={3}
          sx={{ mt: 10 }}
        >
          <Typography variant="h4">
            <b>No Charts Found</b>
          </Typography>
          <NavLink
            to="/edit-dashboard"
            state={{ dashboardId, dashboardsName, collectionId }}
            style={{textDecoration: 'none'}}
          >
            <ButtonPrimaryNormal
              variant="contained"
              startIcon={<img src={Edit} alt="Edit Icon" />}
            >
              Customize Dashboard
            </ButtonPrimaryNormal>
          </NavLink>
        </Stack>
      ) : (
        <Box
          sx={{
            position: "relative",
            width: "100%",
            minHeight: "75vh",
            // resize: 'vertical',
            overflow: "scroll",
          }}
        >
          {dashboardData.map((row) => (
            <BasicChart chartType="column" dashboardData={row} />
          ))}
        </Box>
      )}
    </div>
  );
}
