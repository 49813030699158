import handler from "./handler";
import { BASE_URL } from "../utils/constants";

export const postWidgets = async (value) => {
  let accestoken = localStorage.getItem("accessToken");
  try {
    let response = await fetch(`${BASE_URL}/dashboard/widgets`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accestoken}`,
      },
      body: JSON.stringify(value),
    });
    
    const data = await response.json();
    return {response, data};
  } catch (e) {
    throw e;
  }
};



export const updateWidgets = async (value) => {
  let accestoken = localStorage.getItem("accessToken");
  try {
    let response = await fetch(`${BASE_URL}/dashboard/widgets/update/all`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accestoken}`,
      },
      body: JSON.stringify(value),
    });
    
    return response;
  } catch (e) {
    throw e;
  }
};

export const DeleteWidget = async (widgetId) => {
  let accestoken = localStorage.getItem("accessToken");
  try {
    let response = await fetch(
      `${BASE_URL}/dashboard/widgets/delete/${widgetId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accestoken}`,
        },
      }
    );
    let result = handler(response);

    return result;
  } catch (e) {
    throw e;
  }
};


export const getWidgetSize = async (widgetId) => {
  let accestoken = localStorage.getItem("accessToken");
  try {
    let response = await fetch(
      `${BASE_URL}/dashboard/widget-sizes/widgets/${widgetId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accestoken}`,
        },
      }
    );
    let result = handler(response);

    return result;
  } catch (e) {
    throw e;
  }
};

export const postWidgetSize = async (value) => {
  let accestoken = localStorage.getItem("accessToken");
  try {
    let response = await fetch(`${BASE_URL}/dashboard/widget-sizes`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accestoken}`,
      },
      body: JSON.stringify(value),
    });
    return response;
  } catch (e) {
    throw e;
  }
};


export const updateWidgetSize = async (value) => {
  let accestoken = localStorage.getItem("accessToken");
  try {
    let response = await fetch(`${BASE_URL}/dashboard/widget-sizes/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accestoken}`,
      },
      body: JSON.stringify(value),
    });
    return response;
  } catch (e) {
    throw e;
  }
};


export const getWidgetPosition = async (widgetId) => {
  let accestoken = localStorage.getItem("accessToken");
  try {
    let response = await fetch(
      `${BASE_URL}/dashboard/widget-positions/widgets/${widgetId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accestoken}`,
        },
      }
    );
    let result = handler(response);

    return result;
  } catch (e) {
    throw e;
  }
};


export const postWidgetPosition = async (value) => {
  let accestoken = localStorage.getItem("accessToken");
  try {
    let response = await fetch(`${BASE_URL}/dashboard/widget-positions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accestoken}`,
      },
      body: JSON.stringify(value),
    });
    return response;
  } catch (e) {
    throw e;
  }
};


export const updateWidgetPosition = async (value) => {
  let accestoken = localStorage.getItem("accessToken");
  try {
    let response = await fetch(`${BASE_URL}/dashboard/widget-positions/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accestoken}`,
      },
      body: JSON.stringify(value),
    });
    return response;
  } catch (e) {
    throw e;
  }
};


export const postWidgetFilter = async (value) => {
  let accestoken = localStorage.getItem("accessToken");
  try {
    let response = await fetch(`${BASE_URL}/dashboard/widget-filters`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accestoken}`,
      },
      body: JSON.stringify(value),
    });
    return response;
  } catch (e) {
    throw e;
  }
};


export const getWidgetFilter = async (widgetId) => {
  let accestoken = localStorage.getItem("accessToken");
  try {
    let response = await fetch(
      `${BASE_URL}/dashboard/widget-filters/widgets/${widgetId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accestoken}`,
        },
      }
    );
    let result = handler(response);

    return result;
  } catch (e) {
    throw e;
  }
};

export const updateWidgetFilter = async (value) => {
  let accestoken = localStorage.getItem("accessToken");
  try {
    let response = await fetch(`${BASE_URL}/dashboard/widget-filters/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accestoken}`,
      },
      body: JSON.stringify(value),
    });
    return response;
  } catch (e) {
    throw e;
  }
};





