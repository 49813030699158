import { createTheme } from '@mui/material/styles';

import '@fontsource/dm-sans';

export const Theme = createTheme({
  typography: {
    h1: {
      fontFamily: 'DM Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '3.5em', //56px
      lineHeight: '4.5625em'
  
    },
    h2: {
      ffontFamily: 'DM Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '2.25em', //36px
      lineHeight: '2.9375em'
    },
    h3: {
      fontFamily: 'DM Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1.75em', //28px
      lineHeight: '2.25em'
    },
    h4: {
      fontFamily: 'DM Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1.375em', //22px
      lineHeight: '1.8125em'
    },
    h5: {
      fontFamily: 'DM Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '1.125em', //18px
      lineHeight: '1.4375em'
    },

    body1: {
      fontFamily: 'DM Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '1em', //16px
      lineHeight: '1.3125em'
    },

    body2: {
      fontFamily: 'DM Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '0.875em', //14px
      lineHeight: '1.125em'
    },

    body3: {
      fontFamily: 'DM Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '0.75em', //12px
      lineHeight: '1em'
    },
    body4: {
      fontFamily: 'DM Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '0.62em', //10px
      lineHeight: '1em'
    },

  },

  palette: {
    neutral: {
        letter_Black: '#313131',
        Black_Lit: '#4D4D4D',
        Gray_Dk: '#717171',
        Gray_Lit: '#CED0D2',
        White_Bg_Lit: '#F6F6F6',
        White_Bg_Dk: '#F2F4F7',
        Pure_White_Bg: '#FFFFFF'
    },

    primary: {
        main: '#2294DD',
        Pri_Dk: '#004B8B',
        Primary_Blue: '#2294DD',
        Pri_Lit: '#60CCF7',
        Pri_Bg_Dk: '#BAD4FF',
        Pri_Bg_Lit: '#EEF5FC'
    },

    success: {
        main: '#28C76F',
        Success_Dk: '#1E9553',
        Success: '#28C76F',
        Success_Lit: '#D6FFE8'
    },

    warning: {
        main: '#FF9F43',
        Warning_Dk: '#B25700',
        Warning: '#FF9F43',
        Warning_Lit: '#FFEAD6'
    },

    danger: {
        main: '#EA5455',
        Danger_Dk: '#9F1414',
        Danger: '#EA5455',
        Danger_Lit: '#FFD6D6'
    }
  }

});