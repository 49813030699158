import * as React from "react";
import { useState } from "react";
import { CardContent, Typography, Grid, Stack } from "@mui/material";
import "./style.css";
import MainLogo from "../../assets/fulllogo.svg";
import { ButtonPrimaryNormal, ContainedButton } from "../../components/Buttons";
import { StyledCard } from "../../components/Cards";
import { StyledTextBox } from "../../components/TextBox";
// import KeyIcon from "@mui/icons-material/Key";
import { NavLink } from "react-router-dom";
import validator from "validator";

// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import LanguageIcon from "@mui/icons-material/Language";

export default function StepOne() {
  const [email, setEmail] = useState(null);
  const [error, setError] = useState(false);


  return (
    <div className="main">
      <Grid container spacing={2}>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="space-between"
          sx={{ padding: "2% 1% 0 2%" }}
        >
          <img src={MainLogo} alt="Logo" style={{ width: "8.36375em" }} />
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography color="#1D1E30">Don’t have an account?</Typography>
            <NavLink to="/create-account" className="buttonLinks">
              {/* <ContainedButton variant="contained" >Sign In</ContainedButton> */}
              <ButtonPrimaryNormal variant="contained">
                Sign Up
              </ButtonPrimaryNormal>
            </NavLink>
          </Stack>
        </Grid>
        <Grid direction="column" container alignItems="center">
          <StyledCard className="cardMain">
            <CardContent>
              <Stack spacing={2}>
                <img src={MainLogo} alt="Logo" style={{ width: "8.36375em" }} />
                <br></br>
                <Typography variant="h4" color="#1D1E30">
                  <b>Sign In</b>
                </Typography>
                <Stack spacing={1}>
                  <Typography variant="body2">
                    Please enter your email to sign in
                  </Typography>
                  <StyledTextBox
                    error={ error && (email === "" || email === null || !validator.isEmail(email)) ? true : false}
                    fullWidth
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    id="outlined-adornment-weight"
                  />
                   <Typography variant="body3" color="red">
                    {error && (email === "" || email === null || !validator.isEmail(email)) ? "Enter an email!": ""}
                    {/* {!validator.isEmail(email) ? "Sorry, we don't recognize this account" :""} */}
                  </Typography>
                </Stack>

                <Typography variant="body3">
                  Don’t have an account? &nbsp;
                  <NavLink to="/create-account" className="buttonLinks">
                    <span style={{ color: "#2294DD" }}>Create Account</span>
                  </NavLink>
                </Typography>
                {/* <Typography variant="body3" color="#2294DD">
                  Can’t access the account
                </Typography> */}
                <br></br>

                {email === "" || email === null || !validator.isEmail(email) ? (
                  <ContainedButton
                    fullWidth
                    variant="contained"
                    onClick={() => setError(true)}
                  >
                    Continue
                  </ContainedButton>
                ) : (
                  <NavLink
                    to="/enter-password"
                    state={{ siginEmail: email }}
                    className="buttonLinks"
                  >
                    <ContainedButton fullWidth variant="contained">
                      Continue
                    </ContainedButton>
                  </NavLink>
                )}
              </Stack>
              <br></br>
            </CardContent>
          </StyledCard>
        </Grid>
        {/* <br></br>
          <StyledCard className="cardBottom">
            <CardContent>
              <Stack direction="row" spacing={2} alignItems="center">
                <KeyIcon
                  fontSize="large"
                  style={{ transform: "rotate(320deg)" }}
                />
                <Typography variant="h4">
                  <b>Sign in with SSO</b>
                </Typography>
              </Stack>
            </CardContent>
          </StyledCard> */}
        <Grid
          container
          justifyContent="flex-end"
          alignItems="flex-end"
          style={{ marginRight: "1%", marginBottom: "2%" }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="body3" color="#004B8B">
              <a href="#termsofservice" style={{textDecoration: "none", color: "#2294DD"}}>Terms of Service</a>
            </Typography>
            <Typography variant="body3" color="#004B8B">
            <a href="#privacy" style={{textDecoration: "none", color: "#2294DD"}}>Privacy</a>
            </Typography>
            {/* <Stack direction="row" spacing={1} alignItems="center">
              <LanguageIcon sx={{ color: "#004B8B" }} />
              <Typography variant="body3" color="#004B8B">
                Change region
              </Typography>
              <KeyboardArrowDownIcon sx={{ color: "#004B8B" }} />
            </Stack> */}
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}
