import handler from "./handler";
import { BASE_URL } from "../utils/constants";

export const getPlatforms = async (value) => {
  let accestoken = localStorage.getItem("accessToken");
  try {
    let response = await fetch(
      `${BASE_URL}/core/platforms`,
      {
        method: "GET",
        headers: {
            Authorization: `Bearer ${accestoken}`,
        },
      }
    );
    let result = handler(response);

    return result;
  } catch (e) {
    throw e;
  }
};

export const getPlatformsStores = async (value) => {
  let organizationId = localStorage.getItem("organizationId");
  let accestoken = localStorage.getItem("accessToken");
  try {
    let response = await fetch(
      `${BASE_URL}/core/platform-stores/organizations/${organizationId}`,
      {
        method: "GET",
        headers: {
            Authorization: `Bearer ${accestoken}`,
        },
      }
    );
    let result = handler(response);

    return result;
  } catch (e) {
    throw e;
  }
};

export const postPlatformStore = async (value) => {
  // console.log('value : ' + JSON.stringify(value));
  let accestoken = localStorage.getItem('accessToken')
  try {
    let response = await fetch(`${BASE_URL}/core/platform-stores`, {
      method: "POST",
      headers: {
        'Authorization': `Bearer ${accestoken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(value),
    });
    return response
  } catch (e) {
    throw e;
  }
};

export const getPlatformsDataHistory = async (value) => {
  let platformStoreId = value;
  let accestoken = localStorage.getItem("accessToken");
  try {
    let response = await fetch(
      `${BASE_URL}/core/platform-data-histories/${platformStoreId}`,
      {
        method: "GET",
        headers: {
            Authorization: `Bearer ${accestoken}`,
        },
      }
    );
    let result = handler(response);
    return result;
  } catch (e) {
    throw e;
  }
};


