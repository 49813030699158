import dateFormat from "dateformat";

export function groupByDate(data) {

  
  if (data === undefined || data.length === 0) {
    return null;
  } else {
    var arrByDate = data.reduce((a, b) => {
        
      var i = a.findIndex(
        (x) =>
        
          dateFormat(x.purchaseDate, "mmm, yyyy") ===
          dateFormat(b.purchaseDate, "mmm, yyyy")
      );
      if (i === -1) {
        a.push({
        purchaseDate: b.purchaseDate,
          totalSalesAmountTotal: b.totalSalesAmount,
        });
      } else {
        a[i].totalSalesAmountTotal = a[i].totalSalesAmountTotal + b.totalSalesAmount;
      }
      return a;
    }, []);

    return arrByDate;
  }
}