import * as React from "react";
import {
  Typography,
  Stack,
} from "@mui/material";
import EmailConfirm from "../../../../assets/emailConfirm.svg";

export default function StepOne() {
  return (
    <div>
      <Stack justifyContent="center" alignItems="center">
        <br></br>
        <Stack spacing={1} textAlign="center">
        <Typography variant="h4">
        We’ve sent the confirmation email to you.
        </Typography>
        <Typography variant="body2">
        Please check your inbox or junk folder for cofirmation email.
        </Typography>
        </Stack>
        <img src={EmailConfirm} alt="Email Confirm" style={{ width: "60%" }} />
        <br></br>
        
      </Stack>
    </div>
  );
}
