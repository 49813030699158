import React, { useState, useEffect } from "react";
import {
  Typography,
  Stack,
  Divider,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  MenuItem,
  Menu,
  IconButton,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import moment from "moment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FolderBlue from "../../assets/folderblue.svg";

import { ActionAreaCard } from "../../components/Cards";
import Bluetemp from "../../assets/bluetemp.svg";
import {
  ButtonPrimaryNormal,
  ButtonPrimaryNormalDelete,
  ButtonTertiaryNormal,
} from "../../components/Buttons";
import { getUserDetails } from "../../services/users.services";
import { StyledTextBox } from "../../components/TextBox";
import {
  postDashboard,
  getDashboard,
  DeleteDashboard,
  updateDashboard
} from "../../services/dashboard.services";
import { NavLink } from "react-router-dom";
import "../../assets/style.css";

export default function UnicornHub() {
  const [userDetails, setUserDetails] = useState(null);
  const [dashboardName, setDashboardName] = useState(null);
  const [dashboards, setDashboards] = useState(null);

  const [errorCheckDashboardName, setCheckDashboardName] = useState(false);
  const [nameMessage, setNameMessage] = useState("");

  const [openDialog, setOpenDialog] = useState(false); //Delete Model
  const [open, setOpen] = useState(false); //Create new dashboard
  const [openUpdate, setOpenUpdate] = useState(false); //Update dashboard
  const [menu, setMenu] = useState(null); //Dashboard Options

  const [menuId, setMenuId] = useState(null); //Dashboard ID
  const [menuName, setMenuName] = useState(null);

  const [dashboardUpdatedName, setDashboardUpdatedName] = useState(null);




  const openMenu = Boolean(menu);

  const handleMenuClick = (event) => {
    setMenu(event.currentTarget);
    setMenuId(event.currentTarget.id);
    setMenuName(event.currentTarget.name);
    setDashboardUpdatedName(event.currentTarget.name)
  };
  const handleMenuClose = () => {
    setMenu(null);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenUpdate = () => {
    setMenu(null);
    setOpenUpdate(true)
  };
  const handleCloseUpdate = () => setOpenUpdate(false);

  const handleOpenDialog = () => {
    setMenu(null);
    setOpenDialog(true)
  };
  const handleCloseDialog = () => setOpenDialog(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(false);
      try {
        await getUserDetails().then((data) => {
          setUserDetails(data);
          setIsLoading(true);
        });

        await getDashboard().then((data) => {
          setDashboards(data);
          setIsLoading(true);
        });
      } catch (e) {
        throw e;
      }
    })();
  }, [open, openUpdate, openDialog]);

  if (!userDetails || !dashboards) return null;

  // console.log(dashboards);

  const platformIds = userDetails.platforms.map((row) => row.encryptedId);

  const handleCreateDashboard = (e) => {
    e.preventDefault();

    let error = false;

    const value = {
      collectionId: "632813901f44bf19a5938d67",
      createdType: "CUSTOMIZE",
      description: "no description",
      name: dashboardName,
      organizationId: userDetails.organization.encryptedId,
      platformIds: platformIds,
      status: "CREATED",
    };

    if (dashboardName === null || dashboardName === "") {
      setCheckDashboardName(true);
      setNameMessage("Enter a name for your dashboard");
      error = true;
    } else {
      setCheckDashboardName(false);
      setNameMessage("");
    }

    if (!error) {
      postDashboard(value).then((data) => {
        if (data.ok) {
          handleClose();
        }else if(data.status === 409){
          setCheckDashboardName(true);
          setNameMessage("Dashboard name already exists");
        }
      });
    }
  };

  let welcomeMessage = "";

  var DayTimedata = [
      [22, "Working late"],
      [18, "Good evening"],
      [12, "Good afternoon"],
      [5, "Good morning"],
      [0, "Whoa, early bird"],
    ],
    hr = new Date().getHours();
  for (var i = 0; i < DayTimedata.length; i++) {
    if (hr >= DayTimedata[i][0]) {
      welcomeMessage = DayTimedata[i][1];
      break;
    }
  }

  const handleDeleteDashboard = (e) => {
    e.preventDefault();

    const value = menuId;

    DeleteDashboard(value).then((data) => {
      if (data.ok) {
        handleCloseDialog();
        // window.location.reload(false);
      }
    });
  };


  const handleUpdateDashboard = (e) => {
    e.preventDefault();

    let error = false;

    const value = {
      collectionId: "632813901f44bf19a5938d67",
      createdType: "CUSTOMIZE",
      description: "no description",
      name: dashboardUpdatedName,
      id: menuId, //Dashboard ID
      organizationId: userDetails.organization.encryptedId,
      platformIds: platformIds,
      status: "CREATED",
    };

    if (dashboardUpdatedName === null || dashboardUpdatedName === "") {
      setCheckDashboardName(true);
      setNameMessage("Enter a Name for your Dashboard");
      error = true;
    } else {
      setCheckDashboardName(false);
      setNameMessage("");
    }

    if (!error) {
      updateDashboard(value).then((data) => {
        if (data.ok) {
          handleCloseUpdate();
        }else if(data.status === 409){
          setCheckDashboardName(true);
          setNameMessage("Dashboard name already exists");
        }
      });
    }
  };

  return (
    <div>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography variant="h3">
            {welcomeMessage}, {userDetails.user.firstName}!
          </Typography>
          <Typography variant="h5">
            Let's make decisions based on your data.
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <ButtonPrimaryNormal
            variant="contained"
            startIcon={<AddOutlinedIcon />}
            sx={{ textTransform: "none" }}
            onClick={handleOpen}
          >
            Create New
          </ButtonPrimaryNormal>
        </Stack>
      </Stack>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"xs"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle id="alert-dialog-title">Create New Dashboard</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Typography variant="body2">Collection Name</Typography>
              <StyledTextBox
                fullWidth
                disabled
                placeholder="Collection"
                value="Shopify"
              />
            </Stack>
            <Stack spacing={1}>
              <Typography variant="body2">
                Enter a Name for Dashboard
              </Typography>
              <StyledTextBox
                error={errorCheckDashboardName ? true : false}
                fullWidth
                placeholder="Dashboard Name"
                value={dashboardName}
                onChange={(e) => setDashboardName(e.target.value)}
              />
              <Typography variant="body3" color="red">
                {nameMessage}
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <ButtonTertiaryNormal variant="text" onClick={handleClose}>
            Close
          </ButtonTertiaryNormal>
          <ButtonPrimaryNormal
            variant="contained"
            onClick={(e) => handleCreateDashboard(e)}
          >
            Save
          </ButtonPrimaryNormal>
        </DialogActions>
      </Dialog>

      <Typography marginTop="2%" variant="h5" fontWeight="bold">
        My Dashboards
      </Typography>

      {/* <Typography marginTop="2%" variant="h5" fontWeight="bold">
        Recomendations for You
      </Typography> */}
      <br></br>
      <Divider />
      <br></br>

      {!isLoading ? (
        <img
          src="https://unicorn-dev-assests.s3.amazonaws.com/unicornMiniIcon.svg"
          alt="unicorn"
          className="rotate"
        />
      ) : dashboards.length <= 0 ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={3}
          sx={{ mt: 10 }}
        >
          <Typography variant="h4">
            <b>No Dashboards Found</b>
          </Typography>
          <ButtonPrimaryNormal
            variant="contained"
            startIcon={<AddOutlinedIcon />}
            sx={{ textTransform: "none" }}
            onClick={handleOpen}
          >
            Create New
          </ButtonPrimaryNormal>
        </Stack>
      ) : (
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {dashboards.map((row) => (
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Stack direction="row" spacing={3}>
                <ActionAreaCard>
                  <NavLink
                    to="/dashboard"
                    state={{
                      dashboardId: row.id,
                      dashboardsName: row.name,
                      collectionId: row.collectionId,
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      src={Bluetemp}
                      alt="temp Icon"
                      style={{ padding: "3%", width: "100%", height: "70%" }}
                    />
                  </NavLink>
                  <Divider />
                  <Stack
                    padding="3% 6% 1%"
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                     <NavLink
                      to="/dashboard"
                      state={{
                        dashboardId: row.id,
                        dashboardsName: row.name,
                        collectionId: row.collectionId,
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Stack direction="row" spacing={2}>

                      
                    <img src={FolderBlue} alt="Folder Icon" />
                   
                      <Stack>
                        <Typography variant="body2" color="black">{row.name}</Typography>
                        <Typography variant="body3" color="#999999">
                          Updated on:{" "}
                          {moment(row.lastModifiedWidgetDate)
                            .startOf("hour")
                            .fromNow()}
                        </Typography>
                      </Stack>
                      </Stack>
                    </NavLink>
                    <IconButton onClick={handleMenuClick} id={row.id} name={row.name}>
                      <MoreVertIcon />
                    </IconButton>
                  </Stack>
                </ActionAreaCard>
              </Stack>
            </Grid>
          ))}
          <Menu anchorEl={menu} open={openMenu} onClose={handleMenuClose}>
            <MenuItem onClick={handleOpenDialog}>Delete</MenuItem>
            <MenuItem onClick={handleOpenUpdate}>Edit</MenuItem>
          </Menu>

          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            fullWidth={true}
            maxWidth={"xs"}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <DialogContent>
              <Stack spacing={2}>
                <Typography variant="body2">
                  Are you sure you want to Delete <b>{menuName}</b>
                </Typography>
              </Stack>
            </DialogContent>
            <DialogActions>
              <ButtonTertiaryNormal variant="text" onClick={handleCloseDialog}>
                Close
              </ButtonTertiaryNormal>
              <ButtonPrimaryNormalDelete
                variant="contained"
                onClick={(e) => handleDeleteDashboard(e)}
              >
                Delete
              </ButtonPrimaryNormalDelete>
            </DialogActions>
          </Dialog>

          <Dialog
        open={openUpdate}
        onClose={handleCloseUpdate}
        fullWidth={true}
        maxWidth={"xs"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle id="alert-dialog-title">Update Dashboard</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Typography variant="body2">Collection Name</Typography>
              <StyledTextBox
                fullWidth
                disabled
                placeholder="Collection"
                value="Shopify"
              />
            </Stack>
            <Stack spacing={1}>
              <Typography variant="body2">
                Enter a Name for Dashboard
              </Typography>
              <StyledTextBox
                error={errorCheckDashboardName ? true : false}
                fullWidth
                placeholder="Dashboard Name"
                value={dashboardUpdatedName}
                onChange={(e) => setDashboardUpdatedName(e.target.value)}
              />
              <Typography variant="body3" color="red">
                {nameMessage}
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <ButtonTertiaryNormal variant="text" onClick={handleCloseUpdate}>
            Close
          </ButtonTertiaryNormal>
          <ButtonPrimaryNormal
            variant="contained"
            onClick={(e) => handleUpdateDashboard(e)}
          >
            Save
          </ButtonPrimaryNormal>
        </DialogActions>
      </Dialog>
        </Grid>
      )}
    </div>
  );
}
