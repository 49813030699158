import React from "react";
import { Grid, Typography } from "@mui/material";
import { SingleTextBox } from "../../components/TextBox";
// import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
// import VerticalAlignBottomOutlinedIcon from "@mui/icons-material/VerticalAlignBottomOutlined";
// import VerticalAlignTopOutlinedIcon from "@mui/icons-material/VerticalAlignTopOutlined";
// import Button from "@mui/material/Button";
// import ButtonGroup from "@mui/material/ButtonGroup";
// import Box from "@mui/material/Box";
import "./Style.css";
import { useState, useEffect } from "react";
import { TwitterPicker } from 'react-color';


export default function Personalize(props) {
  const [chartName, setChartName] = useState(null);
  const [color, setColor] = useState('#fff')

  useEffect(() => {
    setChartName(props.chartDetailes.chartDetailes.name)
  }, [props]);
  
  // const value = {
  //   chartTypeId: props.chartDetailes.chartDetailes.chartTypeId,
  //   collectionId: props.chartDetailes.chartDetailes.collectionId,
  //   createdType: props.chartDetailes.chartDetailes.createdType,
  //   dashboardId: props.chartDetailes.chartDetailes.dashboardId,
  //   description: 'no description',
  //   id: props.chartDetailes.chartDetailes.id,
  //   name: chartName,
  //   organizationId: props.chartDetailes.chartDetailes.organizationId,
  //   platformDataType: props.chartDetailes.chartDetailes.platformDataType,
  //   platformStoreIds: props.chartDetailes.chartDetailes.platformStoreIds
  //  }

  // localStorage.setItem(props.chartDetailes.chartDetailes.id, JSON.stringify(value))

  const updateChartName = (chartNameValue, chartColorValue) => {

    setColor(chartColorValue)
    setChartName(chartNameValue)
    const rowId = localStorage.getItem(props.chartDetailes.chartDetailes.id)
    const rowIdObject = JSON.parse(rowId)
  
    if(!rowId){
      localStorage.setItem(props.chartDetailes.chartDetailes.id, JSON.stringify(props.chartDetailes.chartDetailes))
    }else{
       
      localStorage.setItem(props.chartDetailes.chartDetailes.id, JSON.stringify({...rowIdObject, name: chartNameValue, widgetColor: chartColorValue}))
    }
  }




  return (
    <div className="mainBody">
      <Grid container alignItems="center" sx={{ pb: 2 }}>
        <Grid item xs={3}>
          <Typography variant="body2">Chart Title</Typography>
        </Grid>
        <Grid item xs={8}>
          <SingleTextBox fullWidth value={chartName} onChange={(e) => updateChartName(e.target.value, color)}/>
        </Grid>
      </Grid>

      <Grid container alignItems="center" sx={{ pb: 2 }}>
        <Grid item xs={3}>
          <Typography variant="body2">Description</Typography>
        </Grid>
        <Grid item xs={8}>
          <SingleTextBox fullWidth />
        </Grid>
      </Grid>

      {/* <Grid container alignItems="center" sx={{ pb: 2 }}>
        <Grid item xs={3}>
          <Typography variant="body2">Legend Position</Typography>
        </Grid>
        <Grid item xs={6}>
          <ButtonGroup
            size="large"
            variant="outlined"
            aria-label="outlined button group"
          >
            <Button>
              <VerticalAlignBottomOutlinedIcon
                fontSize="12"
                alignItems="center"
                style={{ transform: "rotate(-270deg)" }}
              />
            </Button>
            <Button>
              <VerticalAlignTopOutlinedIcon fontSize="12" />
            </Button>
            <Button>
              <VerticalAlignBottomOutlinedIcon fontSize="12" />
            </Button>
            <Button>
              <VerticalAlignBottomOutlinedIcon
                fontSize="12"
                style={{ transform: "rotate(270deg)" }}
              />
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid xs={3}>
          <Typography variant="body2" color="primary">
            Hide Legend
          </Typography>
        </Grid>
      </Grid> */}

      <Grid container alignItems="center" sx={{ pb: 2 }}>
        <Grid item xs={3}>
          <Typography variant="body2">Color</Typography>
        </Grid>

        <Grid item xs={6}>
          <TwitterPicker triangle="hide" color={color} onChangeComplete={(color) => updateChartName(chartName, color.hex)}/>
          {/* <Stack direction="row" spacing={2} alignItems="center">
            <Box
              sx={{
                width: 20,
                height: 18,
                backgroundColor: "#F7A35C",
              }}
            />
            <Typography variant="body2">#F7A35C</Typography>
            <Typography
              variant="body2"
              sx={{ color: "#718394" }}
              paddingLeft="10%"
            >
              Default
            </Typography>
          </Stack> */}
        </Grid>
        {/* <Grid xs={3}>
          <Typography variant="body2" color="primary">
            Show each
          </Typography>
        </Grid> */}
      </Grid>

      {/* <Grid container alignItems="center" sx={{ pb: 2 }}>
        <Grid xs={3}>
          <Stack direction="row" alignItems="center">
            <Typography variant="body2" alignItems="center">
              Small Multiples
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={9}>
          <Stack direction="row" spacing={2} alignItems="center">
            <StyledTextBox
              sx={{
                height: "32px",
                width: "150px",
                borderRadius: "4px",
                borderBox: "1px solid #C8D2D8",
              }}
              inputProps={{ style: { fontSize: 8 } }}
              endAdornment={
                <InputAdornment position="end">
                  <KeyboardArrowDownOutlinedIcon />
                </InputAdornment>
              }
            />

            <Typography variant="body2">Rows</Typography>
          </Stack>
          <br></br>
        </Grid>

        <Grid xs={3}></Grid>
        <Grid xs={9}>
          <Stack direction="row" spacing={2} alignItems="center">
            <StyledTextBox
              sx={{
                height: "32px",
                width: "150px",
                borderRadius: "4px",
                borderBox: "1px solid #C8D2D8",
              }}
              inputProps={{ style: { fontSize: 8 } }}
              endAdornment={
                <InputAdornment position="end" defaultValue="2">
                  <KeyboardArrowDownOutlinedIcon />
                </InputAdornment>
              }
            />

            <Typography variant="body2">colomns</Typography>
          </Stack>
        </Grid>
      </Grid> */}
    </div>
  );
}
