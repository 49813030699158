import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import "@fontsource/dm-sans";

export const ContainedButton = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  backgroundColor: theme.palette.primary.Primary_Blue,
  textTransform: "capitalize",
  fontWeight: "bold",
  color: theme.palette.neutral.Pure_White_Bg,
  "&:hover": {
    backgroundColor: theme.palette.primary.Primary_Blue,
    boxShadow: ["none"],
  },
  padding: "10px 30px",
}));

export const OutlinedButton = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  backgroundColor: theme.palette.neutral.White_Bg_Dk,
  textTransform: "capitalize",
  fontWeight: "bold",
  color: theme.palette.neutral.Pure_White_Bg,
  "&:hover": {
    backgroundColor: theme.palette.neutral.White_Bg_Dk,
    boxShadow: ["none"],
  },
  padding: "10px 30px",
}));

export const TextButton = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  backgroundColor: theme.palette.primary.Pure_White_Bg,
  textTransform: "capitalize",
  fontFamily: "DM Sans",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "0.875em", //14px
  lineHeight: "1.125em",
  color: theme.palette.neutral.Black_Lit,
  "&:hover": {
    backgroundColor: theme.palette.primary.Pri_Bg_Lit,
    boxShadow: ["none"],
  },
  padding: "8px 12px",
}));

/* Primary Button Section */

// Primary Button Large
export const ButtonPrimaryLarge = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  // width: "26.625em",
  height: "3em",
  borderRadius: "0.5em",
  backgroundColor: theme.palette.primary.Primary_Blue,
  textTransform: "capitalize",
  fontWeight: "bold",
  color: theme.palette.neutral.Pure_White_Bg,
  "&:hover": {
    backgroundColor: theme.palette.primary.Primary_Blue,
    boxShadow: ["none"],
  },
  padding: "8px 16px 8px 16px",
}));

// Primary Button Normal
export const ButtonPrimaryNormal = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  // width: "9.75em",
  // height: "2.5em",
  borderRadius: "0.5em",
  backgroundColor: theme.palette.primary.Primary_Blue,
  textTransform: "capitalize",
  fontWeight: "bold",
  color: theme.palette.neutral.Pure_White_Bg,
  "&:hover": {
    backgroundColor: theme.palette.primary.Primary_Blue,
    boxShadow: ["none"],
  },
  padding: "8px 16px 8px 16px",
}));


// Delete Button Normal
export const ButtonPrimaryNormalDelete = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  // width: "9.75em",
  height: "2.5em",
  borderRadius: "0.5em",
  backgroundColor: theme.palette.danger.main,
  textTransform: "capitalize",
  fontWeight: "bold",
  color: theme.palette.neutral.Pure_White_Bg,
  "&:hover": {
    backgroundColor: theme.palette.danger.main,
    boxShadow: ["none"],
  },
  padding: "8px 16px 8px 16px",
}));

// Primary button Small/Label
export const ButtonPrimarySmall = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  width: "5.4375em",
  height: "1.3125em",
  borderRadius: "0.25em",
  backgroundColor: theme.palette.primary.Primary_Blue,
  textTransform: "capitalize",
  fontWeight: "bold",
  color: theme.palette.primary.Primary_Blue,
  "&:hover": {
    backgroundColor: theme.palette.primary.Primary_Blue,
    boxShadow: ["none"],
  },
  padding: "4px 16px 4px 16px",
}));

/*Secondary Button Section */

// Secondary Button Large
export const ButtonSecondaryLarge = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  width: "26.625em",
  height: "3em",
  borderRadius: "0.5em",
  backgroundColor: theme.palette.neutral.Pure_White_Bg,
  border: "1px solid #2294DD",
  textTransform: "capitalize",
  fontWeight: "bold",
  color: theme.palette.primary.Primary_Blue,
  padding: "8px 16px 8px 16px",
}));

//Secondary Button Normal
export const ButtonSecondaryNormal = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  // width: "9.75em",
  // height: "2.5em",
  borderRadius: "0.5em",
  backgroundColor: theme.palette.neutral.Pure_White_Bg,
  border: "1px solid #2294DD",
  textTransform: "capitalize",
  fontWeight: "bold",
  color: theme.palette.primary.Primary_Blue,
  padding: "8px 16px 8px 16px",
}));

// Secondary Button Small
export const ButtonSecondarySmall = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  width: "5.4375em",
  height: "1.3125em",
  borderRadius: "0.25em",
  backgroundColor: theme.palette.neutral.Pure_White_Bg,
  border: "1px solid #2294DD",
  textTransform: "capitalize",
  fontWeight: "bold",
  color: theme.palette.primary.Primary_Blue,
  padding: "4px 16px 4px 16px",
}));

/* Tertiary Button Section */

// Tertiary Button Large
export const ButtonTertiaryLarge = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  width: "26.625em",
  height: "3em",
  borderRadius: "0.5em",
  // backgroundColor: theme.palette.primary.Primary_Blue,
  textTransform: "capitalize",
  fontWeight: "bold",
  color: theme.palette.neutral.Black_Lit,
  "&:hover": {
    backgroundColor: theme.palette.primary.Pri_Bg_Lit,
    boxShadow: ["none"],
  },
  padding: "8px 16px 8px 16px",
}));

// Tertiary Button Normal
export const ButtonTertiaryNormal = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  // width: "9.75em",
  height: "2.5em",
  borderRadius: "0.5em",
  // backgroundColor: theme.palette.primary.Primary_Blue,
  textTransform: "capitalize",
  fontWeight: "bold",
  color: theme.palette.neutral.Black_Lit,
  "&:hover": {
    backgroundColor: theme.palette.neutral.Pri_Bg_Lit,
    boxShadow: ["none"],
  },
  padding: "8px 16px 8px 16px",
}));

// Tertiary button Small/Label
export const ButtonTertiarySmall = styled(Button)(({ theme }) => ({
  boxShadow: ["none"],
  width: "5.4375em",
  height: "1.3125em",
  borderRadius: "0.25em",
  // backgroundColor: theme.palette.primary.Primary_Blue,
  textTransform: "capitalize",
  fontWeight: "bold",
  color: theme.palette.neutral.Black_Lit,
  "&:hover": {
    backgroundColor: theme.palette.primary.Pri_Bg_Lit,
    boxShadow: ["none"],
  },
  padding: "4px 16px 4px 16px",
}));
