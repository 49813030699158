import React from "react";
import { useState, useEffect } from "react";
import MenuBar from "./MenuBar";
import { getUserDetails } from "../../../services/users.services";

import {
  CardContent,
  Typography,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Button,
  Box,
  Stack,
} from "@mui/material";
import { StyledCard } from "../../../components/Cards";
import "../style.css";
import StepOne from "./Steps/StepOne";
import StepTwo from "./Steps/StepTwo";
import StepThree from "./Steps/StepThree";
import StepFour from "./Steps/StepFour";
import { useNavigate } from "react-router-dom";

// import { ContainedButton } from "../../../components/Buttons";

const steps = [
  "Email Confirmation",
  "Create Profile",
  "Set Up Company",
  "Connect Application",
];

export default function Index() {
  const [activeStep, setActiveStep] = useState(0);
  const [userDetails, setUserDetails] = useState(null);
  const navigate = useNavigate();


  useEffect(() => {
    (async () => {
      try {
        if(!localStorage.getItem('accessToken')){navigate('/signin')} 
        await getUserDetails().then((data) => {
          setUserDetails(data);
          console.log(data.user.userOnboardingStatus)
          if (data.user.userOnboardingStatus === "user-email-confirmation-pending") {
            setActiveStep(0);
          }else if (data.user.userOnboardingStatus === "user-email-confirmation-success") {
            setActiveStep(1);
          }else if(data.user.userOnboardingStatus === "create-user-profile"){
            setActiveStep(2);
          }else if(data.user.userOnboardingStatus === "setup-organization"){
            setActiveStep(3);
          }
          else if(data.user.userOnboardingStatus === "completed"){
            navigate("/unicorn-hub");
            //setActiveStep(3);
          }else{
            setActiveStep(1);
          }
        });
      } catch (e) {
        throw e;
      }
    })();
    // eslint-disable-next-line
  }, []);

  if (!userDetails) return null;




  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <>
      <MenuBar />

      <div className="mainOnboarding">
        {/*  */}
        <br></br>
        <Grid container>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <StyledCard className="onBoardingCard">
              <CardContent>
                <Stepper activeStep={activeStep} variant="progress">
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                {activeStep === steps.length ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button onClick={handleReset}>Reset</Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <br></br>
                    {activeStep === 0 ? (
                      <StepOne />
                    ) : activeStep === 1 ? (
                      <StepTwo data={userDetails}/>
                    ) : activeStep === 2 ? (
                      <StepThree data={userDetails}/>
                    ) : activeStep === 3 ? (
                      <StepFour data={userDetails}/>
                    ) : (
                      ""
                    )}
                    <br></br>
                    <Stack justifyContent="flex-end" alignItems="center">
                      {/* <ContainedButton
                        variant="contained"
                        sx={{ width: "50%" }}
                        onClick={handleNext}
                      >
                        {activeStep === 0
                          ? "Go to Email"
                          : activeStep === 1
                          ? "Continue"
                          : activeStep === 2
                          ? "Continue"
                          : activeStep === 3
                          ? "Finish Set Up"
                          : ""}
                      </ContainedButton> */}
                      <Typography
                        variant="body2"
                        color="#2294DD"
                        textAlign="center"
                        onClick={handleBack}
                        sx={{ cursor: "pointer" }}
                      >
                        {activeStep === 0
                          ? "Resend"
                          : activeStep === 1
                          ? ""
                          : activeStep === 2
                          ? "Back"
                          : activeStep === 3
                          ? "Back"
                          : ""}
                      </Typography>
                    </Stack>
                  </React.Fragment>
                )}
              </CardContent>
            </StyledCard>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
