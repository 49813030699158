import handler from "./handler";
import { BASE_URL } from "../utils/constants";

export const getCharts = async () => {
  let accestoken = localStorage.getItem("accessToken");
  try {
    let response = await fetch(
      `${BASE_URL}/dashboard/chart-types`,
      {
        method: "GET",
        headers: {
            Authorization: `Bearer ${accestoken}`,
        },
      }
    );
    let result = handler(response);

    return result;
  } catch (e) {
    throw e;
  }
};

export const getChartsById = async (value) => {
  let accestoken = localStorage.getItem("accessToken");
  try {
    let response = await fetch(
      `${BASE_URL}/dashboard/chart-types/${value}`,
      {
        method: "GET",
        headers: {
            Authorization: `Bearer ${accestoken}`,
        },
      }
    );
    let result = handler(response);

    return result;
  } catch (e) {
    throw e;
  }
};
