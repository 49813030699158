
import { BASE_URL } from "../utils/constants";


export const postSupportTicket = async (value) => {
  // console.log('value : ' + JSON.stringify(value));
  let accestoken = localStorage.getItem("accessToken");
  try {
    let response = await fetch(`${BASE_URL}/core/tickets`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accestoken}`,
      },
      body: JSON.stringify(value),
    });
  
    return response
  } catch (e) {
    throw e;
  }
};


